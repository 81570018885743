import React from 'react';
import { Container, Back } from './styles';
import { InputPassord } from './../../components/Input/index';
import Button from './../../components/ButtonComponent';
import { BackCustomized } from '../../components/BackComponent';
import { URL_PORTAL } from './../../config/urls';
import { showMessage } from '../../hooks/messages';
import { redefinePassword } from './../../hooks/user';
import { useForm } from 'react-hook-form';
import Loading from '../../components/Loading';
import { useHistory } from 'react-router-dom';

type FormValues = {
  password: string;
  confirmPassword: string;
};

function RedefinePassword() {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { password: '', confirmPassword: '' },
  });
  const history = useHistory();

  const submit = async (data: FormValues) => {
    setIsLoading(true);
    const password = await redefinePassword(data);
    password &&
      showMessage({ intent: password.status, message: password.message });
    setIsLoading(false);
    if (password?.status === 'success') {
      setTimeout(() => history.push('/home'), 2000);
    }
  };

  return (
    <Container>
      <Back>
        <BackCustomized text="Voltar" url={URL_PORTAL}></BackCustomized>
      </Back>
      <h2>Defina sua senha de acesso</h2>
      <span>Depois disso você poderá prosseguir para a plataforma</span>

      <form onSubmit={handleSubmit(submit)}>
        <InputPassord
          placeholder="Digite aqui sua senha"
          label="nova senha"
          type="password"
          {...register('password', {
            required: 'Nova senha é um campo obrigatório.',
          })}
          error={errors.password?.message}
        />
        <InputPassord
          placeholder="Digite novamente a sua senha"
          label="Confirme sua senha"
          type="password"
          {...register('confirmPassword', {
            required: 'Confirmar senha é um campo obrigatório.',
            validate: {
              passwordIsEqual: (value) => {
                const { password } = getValues();
                return password === value || 'As senhas não são iguais';
              },
            },
          })}
          error={errors.confirmPassword?.message}
        />
        <Button type="submit" content={isLoading ? <Loading /> : 'Continuar'} />
      </form>
    </Container>
  );
}

export default RedefinePassword;
