import React from 'react';
import { Container } from './styles';

interface ButtonProps {
  type: 'button' | 'submit' | 'reset';
  content?: any;
}

const Button = ({ type, content }: ButtonProps) => {
  return <Container type={type}>{content}</Container>;
};

export default Button;
