import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  top: 0px;
  left: 20px;
  font-size: 1rem;
  width: 200px;

  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  color: #6fcaff;
`;

export const ContainerSVG = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  width: 2.5rem;
  svg {
    width: 25px;
    height: 18px;
    fill: #6fcaff;
  }
`;
