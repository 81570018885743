import React from 'react';
import Moment from 'react-moment';
import { Container, Date, Content, Image, Message } from './styles';
import { NotifyProps } from './../../domain/Notify/Notify';

interface NotifyCardProps {
  readMessage: (notify: NotifyProps) => void;
  notify: NotifyProps;
}

const handleBackground = () => {
  const colors = ['#2CA1E5', '#FF6F69', '#9E74D4', '#FFE033', '#5375CD'];
  const i = Math.floor(Math.random() * (colors.length - 1));
  return colors[i];
};

const NotifyCard = ({ notify, readMessage }: NotifyCardProps) => {
  return (
    <Container read={!notify.read} onClick={() => readMessage(notify)}>
      <Date>
        <Moment format="DD/MM/YYYY">{notify.created_at}</Moment>
      </Date>
      <Content>
        <Image background={handleBackground()}>
          {notify?.idSender?.name[0] + notify?.idSender?.name[1]}
        </Image>
        <Message>
          <label>{notify?.idSender?.name}</label> {notify.text}
        </Message>
      </Content>
    </Container>
  );
};

export default NotifyCard;
