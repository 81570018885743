import styled from '@emotion/styled';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Main = styled.div`
  width: 100%;
  background: #ebecf1;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  min-height: 70vh;
  padding-bottom: 200px;
`;
