import styled from '@emotion/styled';

interface ItemProps {
  selected?: boolean;
  read?: boolean;
}

interface NotifyProps {
  show?: boolean;
}
export const Menu = styled.div`
  width: 100%;
  background: #fefeff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 1);
  height: 70px;
  @media (max-width: 500px) {
    padding-top: 20px;
    align-items: center;
  }
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: #fefeff;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 90px;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
  }
`;

export const Left = styled.div`
  width: 18%;
  span {
    font-weight: bold;
    color: #0064b0;
  }
  label {
    color: #9aa0ba;
  }
  label,
  span,
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  border-bottom: 3px solid transparent;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;

  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Item = styled.div<ItemProps>`
  display: flex;
  width: 110px;
  align-items: center;
  justify-content: center;
  border-color: 5px ${({ selected }) => (selected ? '#0E57B5' : 'none')};
  color: ${({ selected }) => (selected ? '#0E57B5' : '#9aa0ba')};
  border-bottom: ${({ selected }) =>
    selected ? '3px solid' : '3px solid transparent'};
  margin-left: 30px;
  letter-spacing: 2px;
  padding-bottom: 2px;
  label {
    margin-left: 3%;
  }
  svg,
  label:hover {
    cursor: pointer;
  }
  svg {
    fill: #9aa0ba;
    width: 20px;
    height: 20px;
  }
`;

export const Right = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;
  color: #db3535;

  &:hover {
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Notify = styled.div<ItemProps>`
  width: 100px;
  display: flex;
  margin-right: 20px;
  color: ${({ selected }) => (selected ? '#0E57B5' : '#9aa0ba')};
  position: relative;
  border-bottom: ${({ selected }) =>
    selected ? '3px solid' : '3px solid transparent'};

  svg {
    width: 20px;
    height: 20px;
    fill: #db3535;
  }

  label {
    padding-left: 5px;
  }
  label,
  svg:hover {
    cursor: pointer;
  }

  div:first-of-type {
    position: absolute;
    left: 100%;
    top: -20%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${({ read }) => (read ? 'red' : 'none')};
  }
`;

export const BoxNotify = styled.div<NotifyProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 15%;
  width: 26rem;
  height: 400px;
  background: #fefeff;
  box-shadow: 0px 20px 25px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-y: scroll;
  z-index: 3;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f4f4f4;
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    border-radius: 10px;
    background: #f4f4f4;
    width: 13px;
    height: 13px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #dad7d7;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  scrollbar-color: #dad7d7;
  scrollbar-width: thin;

  @media (max-width: 500px) {
    margin-top: 50px;
  }
`;

export const HeadNotify = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  margin-bottom: 5%;
  padding-right: 2%;
  width: 100%;
  height: 10px;
  top: 0px;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: Mulish;
  font-size: 1rem;
  line-height: 13px;
  color: #6fcaff;
`;

export const Out = styled.div`
  width: auto;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
    fill: #db3535;
  }

  label,
  svg:hover {
    cursor: pointer;
  }
`;
