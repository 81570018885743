import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  height: min-content;
  width: max-content;
  margin: 1rem 0px 0px 1rem;
  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  color: #6fcaff;
  display: flex;
  font-weight: bold;
`;

export const ContainerSVG = styled.div`
  svg {
    width: 25px;
    height: 18px;
    fill: #6fcaff;
  }
`;
