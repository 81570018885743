import { renderError } from '../hooks/error';
import api from './api';

interface MessageProps {
  status: 'success' | 'error' | 'warning';
  message: string;
}

interface UserProps {
  id: number;
  username: string;
  name: string;
  email: string;
  course: string;
  school: string;
  age: string;
  group: number;
  role: string;
  platform: string;
  phone: string;
  area: string;
  password?: string;
  redefinedPassword: boolean;
}

interface LoginProps {
  identifier: string;
  password: string;
}

const saveToken = (token: string) => {
  return localStorage.setItem('@alunoensina_teacher:token', token);
};

export const saveUser = (user: UserProps) => {
  return localStorage.setItem(
    '@alunoensina_teacher:user',
    JSON.stringify(user)
  );
};

export const saveUserId = (user: string) => {
  return localStorage.setItem(
    '@alunoensina_teacher:user_id',
    JSON.stringify(user)
  );
};

export const getToken = () => {
  return localStorage.getItem('@alunoensina_teacher:token');
};

export const getUser = () => {
  const user = localStorage.getItem('@alunoensina_teacher:user');
  if (user) {
    return JSON.parse(user);
  }
  return false;
};

export const getUserId = () => {
  const user = localStorage.getItem('@alunoensina_teacher:user_id');
  if (user) {
    return JSON.parse(user);
  }
  return;
};

export const getSession = () => {
  return [
    { user: localStorage.getItem('@alunoensina_teacher:user') },
    { token: localStorage.getItem('@alunoensina_teacher:token') },
  ];
};

export const isAuth = () => {
  return !!getUser() && !!getToken();
};

export const authenticate = async (
  auth: LoginProps
): Promise<MessageProps | undefined> => {
  try {
    const response = await api.post('/auth/local', auth);
    if (response.status === 200) {
      const data = response.data;
      if (data.user.role.name !== 'Teacher') {
        return {
          status: 'warning',
          message:
            'O usuário não tem permissão ou não faz parte do time de professores!',
        };
      }
      saveToken(data.jwt);
      saveUserId(data.user.id);
      saveUser({
        platform: data.user.platform,
        area: data.user.area,
        username: data.user.username,
        name: data.user.name,
        email: data.user.email,
        id: data.user.id,
        course: data.user.course,
        school: data.user.school,
        age: data.user.age,
        phone: data.user.phone,
        group: data.user.group,
        role: data.user.role,
        redefinedPassword: data.user.redefinedPassword,
      });
      return { status: 'success', message: 'Bem vindo!' };
    }
  } catch (err) {
    renderError(err);
    return { status: 'error', message: 'Email e/ou senha inválido.' };
  }
};

export const confirmEmail = (email: string) => {
  return api.post('/auth/send-email-confirmation', {
    email,
  });
};

export const signOut = () => {
  localStorage.removeItem('@alunoensina_teacher:token');
  localStorage.removeItem('@alunoensina_teacher:user');
};

export const forgetPassword = async (
  email: string
): Promise<MessageProps | undefined> => {
  try {
    const response = await api.post('/auth/forgot-password', {
      email,
    });
    if (response.status === 200) {
      return {
        status: 'success',
        message: 'E-mail para recuperar a senha foi enviado com sucesso!',
      };
    }
  } catch (err) {
    renderError(err);
    return { status: 'error', message: 'Falha ao enviar e-mail.' };
  }
};
