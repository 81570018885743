import React from 'react';

import { Container, Button } from './styles';

interface CheckboxProps {
  isChecked: boolean;
  text: string;
  name: string;
  change: () => void;
}

const Checkbox = ({ text, change, isChecked }: CheckboxProps) => {
  return (
    <Container onClick={change}>
      <Button isChecked={isChecked} type="button">
        {isChecked && (
          <svg>
            <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1.022,1.022,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16a1,1,0,0,0,0-1.47Z" />
          </svg>
        )}
      </Button>
      <label> {text} </label>
    </Container>
  );
};

export default Checkbox;
