import React from 'react';
import { ContainerFooter, Content, Image, Icons } from './styles';
import Logo from '../../images/logo.png';
const Footer = () => {
  const handleIcons = (index: number, path: string, link: string) => {
    return (
      <a href={link} key={index}>
        <svg>
          <path d={path} />
        </svg>
      </a>
    );
  };

  const handleNavigate = (index: number, text: string, link: string) => {
    return (
      <a key={index} href={link}>
        {text}
      </a>
    );
  };

  const icons = [
    {
      path:
        'M23.459,6.84h0a1.679,1.679,0,1,0,1.679,1.679A1.679,1.679,0,0,0,23.459,6.84Zm6.435,3.385a10.618,10.618,0,0,0-.644-3.4A6.911,6.911,0,0,0,27.628,4.35a6.575,6.575,0,0,0-2.476-1.609,10.212,10.212,0,0,0-3.4-.657C20.27,2,19.794,2,15.989,2s-4.281,0-5.764.084a10.212,10.212,0,0,0-3.4.657A6.687,6.687,0,0,0,4.35,4.35,6.575,6.575,0,0,0,2.741,6.826a10.212,10.212,0,0,0-.657,3.4C2,11.708,2,12.184,2,15.989s0,4.281.084,5.764a10.212,10.212,0,0,0,.657,3.4A6.575,6.575,0,0,0,4.35,27.628a6.687,6.687,0,0,0,2.476,1.609,10.212,10.212,0,0,0,3.4.657c1.483.084,1.958.084,5.764.084s4.281,0,5.764-.084a10.212,10.212,0,0,0,3.4-.657,6.575,6.575,0,0,0,2.476-1.609,6.785,6.785,0,0,0,1.623-2.476,10.618,10.618,0,0,0,.643-3.4c0-1.483.084-1.958.084-5.764S29.978,11.708,29.894,10.226ZM27.376,21.585a7.848,7.848,0,0,1-.476,2.6A4.281,4.281,0,0,1,25.852,25.8a4.463,4.463,0,0,1-1.609,1.049,7.848,7.848,0,0,1-2.6.476c-1.4.07-1.917.084-5.6.084s-4.2,0-5.6-.084a8.016,8.016,0,0,1-2.714-.42A4.574,4.574,0,0,1,6.2,25.852a4.2,4.2,0,0,1-1.035-1.609,7.75,7.75,0,0,1-.56-2.658c0-1.4-.084-1.917-.084-5.6s0-4.2.084-5.6a7.75,7.75,0,0,1,.49-2.658A4.2,4.2,0,0,1,6.2,6.2,4.393,4.393,0,0,1,7.736,5.078,8.016,8.016,0,0,1,10.394,4.6c1.4,0,1.917-.084,5.6-.084s4.2,0,5.6.084a7.848,7.848,0,0,1,2.6.476A4.281,4.281,0,0,1,25.852,6.2,4.281,4.281,0,0,1,26.9,7.736a7.848,7.848,0,0,1,.476,2.658c.07,1.4.084,1.917.084,5.6S27.446,20.186,27.376,21.585ZM15.989,8.813a7.162,7.162,0,1,0,5.088,2.093,7.176,7.176,0,0,0-5.088-2.093Zm0,11.835a4.658,4.658,0,1,1,4.658-4.658,4.658,4.658,0,0,1-4.658,4.658Z',
      link: 'https://www.instagram.com/alunoensina/',
    },
    {
      path:
        'M18.515,6.644h2.63V2.2A36.527,36.527,0,0,0,17.312,2C13.507,2,10.9,4.322,10.9,8.575V12.24H6.61v4.98H10.9V29.978h5.148V17.22h4.281l.644-4.98H16.053V9.065C16.053,7.6,16.444,6.644,18.515,6.644Z',
      link: 'https://www.facebook.com/alunoensina',
    },
    {
      path:
        'M15.984,2A13.989,13.989,0,1,0,29.973,15.989,13.989,13.989,0,0,0,15.984,2Zm4.448,21.2a.987.987,0,0,1-1.4.492l-3.8-2.951-2.437,2.249a.419.419,0,0,1-.4.054l.467-4.181.015.012.01-.083S19.722,12.568,20,12.3s.189-.322.189-.322c.016-.322-.506,0-.506,0l-9.055,5.825L6.858,16.522s-.579-.208-.634-.664.653-.7.653-.7L21.869,9.2s1.232-.549,1.232.36Z',
      link: 'https://t.me/joinchat/AAAAAFC-R9Q1-jwtBSMKDg',
    },
  ];

  const menuLinks = [
    { text: 'INÍCIO', link: 'https://alunoensina.com/' },
    { text: 'Sobre nós', link: 'https://alunoensina.com/sobre-nos/' },
    { text: 'Professores', link: 'https://alunoensina.com/professores/' },
    { text: 'FAQ', link: 'https://alunoensina.com/faq/' },
  ];

  return (
    <ContainerFooter>
      <Content>
        <Image>
          <img src={Logo} width="125px" height="126px" alt="logo" />
          <div>
            <p>Copyright © 2020 Aluno Ensina | </p>
            <p>A maior plataforma de ensino comunitário do Brasil</p>
          </div>
        </Image>
        <Icons>
          <div>
            {icons.map((icon, index) =>
              handleIcons(index, icon.path, icon.link)
            )}
          </div>
          <div>
            {menuLinks.map((menu, index) =>
              handleNavigate(index, menu.text, menu.link)
            )}
          </div>
        </Icons>
      </Content>
    </ContainerFooter>
  );
};
export default Footer;
