import * as React from 'react';
import { Redirect, Route as ReactRoute } from 'react-router-dom';
import { RouteProps } from './routes';
import { getUser } from './../services/auth';

function AuthRoutes({ component: Component, ...rest }: RouteProps) {
  const auth = getUser();
  return (
    <ReactRoute
      {...rest}
      render={() =>
        !auth ? (
          <Redirect to="/" />
        ) : auth.redefinedPassword ? (
          <Component />
        ) : (
          <Redirect to="/redefine-password" />
        )
      }
    />
  );
}

export default AuthRoutes;
