import React, { useEffect } from 'react';
import { getUser } from '../../services/auth';
import { changeCustomFields } from '../../hooks/user';
import Button from '../ButtonComponent';
import Loading from '../../components/Loading';
import { Input } from '../Input';
import { useForm } from 'react-hook-form';
import { Container } from './styles';

type FormValues = {
  id: string;
  name: string;
  email: string;
  phone: string;
};

const ProfileSimple: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { id: '', name: '', email: '', phone: '' },
  });

  useEffect(() => {
    const obj = getUser();
    reset({
      id: obj.id,
      name: obj.name,
      email: obj.email,
      phone: obj.phone,
    });
  }, [reset]);

  const submit = (data: FormValues) => {
    setIsLoading(true);
    changeCustomFields(data);
    setIsLoading(false);
  };

  return (
    <Container>
      <h2>Dados Cadastrais</h2>
      <h5>Altere seus dados cadastrais aqui.</h5>
      <form onSubmit={handleSubmit(submit)}>
        <Input
          placeholder="Digite seu nome"
          label="Nome"
          type="text"
          {...register('name', {
            required: 'Nome é um campo obrigatório.',
          })}
          error={errors.name?.message}
        />
        <Input
          placeholder="Digite seu e-mail"
          label="Email"
          type="email"
          {...register('email', {
            required: 'Email é um campo obrigatório.',
          })}
          error={errors.email?.message}
        />
        <Input
          placeholder="Digite seu telefone"
          label="Telefone"
          type="text"
          {...register('phone', {
            required: 'Telefone é um campo obrigatório.',
          })}
          error={errors.phone?.message}
        />
        <Button
          type="submit"
          content={isLoading ? <Loading /> : 'Editar Dados'}
        />
      </form>
    </Container>
  );
};

export default ProfileSimple;
