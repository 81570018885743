import { InputHTMLAttributes } from 'hoist-non-react-statics/node_modules/@types/react';
import React, { useState } from 'react';
import { Container, InputComponent, Label, Svg } from './styles';

interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  isGreyScale?: boolean;
  label: string;
  error: string | undefined;
}

export const InputPassord = React.forwardRef((props: PropsInput, ref: any) => {
  const { label, error, ...prop } = props;
  const [visible, setVisible] = useState(false);
  return (
    <Container>
      <Label>{label}</Label>
      <Svg visible={visible} onClick={() => setVisible(!visible)}>
        {visible ? svgVisiblePassword() : svgInvisiblePassword()}
      </Svg>
      <InputComponent
        ref={ref}
        type={visible ? 'text' : 'password'}
        {...prop}
      />
      {error ? <p>{error}</p> : null}
    </Container>
  );
});

export const Input = React.forwardRef((props: PropsInput, ref: any) => {
  const { isGreyScale, label, error, ...prop } = props;
  return (
    <Container>
      <Label isGreyScale={!!isGreyScale}>{label}</Label>
      <InputComponent ref={ref} {...prop} isGreyScale={!!isGreyScale} />
      {error ? <p>{error}</p> : null}
    </Container>
  );
});

function svgVisiblePassword() {
  return (
    <path
      d="M25.4 9.5C22.875 3.638 18.125 0 13 0S3.125 3.638.6 9.5a1.25 1.25 0 0 0 0 1C3.125 16.363 7.875 20 13 20s9.875-3.637 12.4-9.5a1.249 1.249 0 0 0 0-1Zm-12.4 8c-3.962 0-7.712-2.863-9.875-7.5C5.288 5.362 9.038 2.5 13 2.5c3.962 0 7.712 2.862 9.875 7.5-2.163 4.637-5.913 7.5-9.875 7.5ZM13 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 7.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
      fill="#0e57b5"
    />
  );
}

function svgInvisiblePassword() {
  return (
    <path
      d="M11.675 5.6c.438-.067.881-.1 1.325-.1 3.975 0 7.712 2.862 9.887 7.5-.332.706-.708 1.39-1.125 2.05a1.25 1.25 0 0 0 .062 1.44 1.25 1.25 0 0 0 2.063-.127 19.714 19.714 0 0 0 1.513-2.875 1.25 1.25 0 0 0 0-.988C22.875 6.637 18.125 3 13 3a9.712 9.712 0 0 0-1.75.15 1.268 1.268 0 1 0 .425 2.5V5.6ZM2.637.862A1.255 1.255 0 1 0 .862 2.637L4.737 6.5a18.275 18.275 0 0 0-4.137 6 1.25 1.25 0 0 0 0 1C3.125 19.363 7.875 23 13 23a11.575 11.575 0 0 0 6.313-1.925l4.05 4.063a1.25 1.25 0 0 0 1.774 0 1.252 1.252 0 0 0 0-1.776L2.637.863Zm7.95 11.488 3.063 3.063a2.5 2.5 0 0 1-3.063-3.063ZM13 20.5c-3.975 0-7.713-2.863-9.875-7.5A15.113 15.113 0 0 1 6.5 8.262L8.712 10.5a5 5 0 0 0 6.788 6.788l1.988 1.962A9.05 9.05 0 0 1 13 20.5Z"
      fill="#0e57b5"
    />
  );
}
