import api from '../services/api';
import { getUserId } from '../services/auth';
import { renderError } from './error';
import { showMessage } from './messages';

export const createComment = async (id_essay: number, text: string) => {
  const id_user = getUserId();
  try {
    const response = await api.post('/essay-conversations', {
      id_user,
      text,
      id_essay,
    });
    showMessage({
      intent: 'success',
      message: 'Comentário criado com sucesso!',
    });
    return response.data;
  } catch (err) {
    renderError(err);
    return;
  }
};
