import styled from '@emotion/styled';

interface ItemProps {
  selected?: boolean;
}

interface ButtonProps {
  background: string;
  color: string;
}

export const Item = styled.div<ItemProps>`
  width: 10 0%;
  margin-bottom: 10px;
  font-size: 1rem;
  border-radius: 10px;
  padding: 10px;

  background: #fefeff;
  box-shadow: 0px 12px 25px 5px rgba(0, 0, 0, 0.05);
`;

export const Info = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 15% 2fr;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    label {
      margin-top: 0px;
      top: 0;
      padding-top: 0px;
      font-family: Mulish;
      font-weight: bold;
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #6fcaff;
      margin-bottom: 10px;
    }

    span {
      font-family: Mulish;
      font-size: 1rem;
      color: #050a24;
    }

    &:not(:first-of-type) {
      margin: 0 10%;
    }

    &:not(:last-of-type) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
`;

export const Status = styled.div<ButtonProps>`
  display: flex;
  width: 145px;
  height: 50px;
  padding-left: 10px;
  border-radius: 5px;
  color: ${({ color }) => (color ? color : '')};
  background-color: ${({ background }) => (background ? background : '')};
  justify-content: flex-start !important;
  div:first-of-type {
    background: ${({ color }) => (color ? color : '')};
    border-radius: 2px;
    width: 15%;
    height: 40%;
  }
  &:hover {
    cursor: pointer;
  }
`;
