import styled from '@emotion/styled';

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #050a24 0% 0% no-repeat padding-box;
  opacity: 1;
  min-height: 290px;
`;

export const Content = styled.div`
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  max-width: 1200px;
  height: 100%;
  @media (max-width: 850px) {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  img {
    margin-right: 20px;
  }
  p {
    margin: 5px 0px;
    text-align: left;
    font-size: 1.1rem;
    font-style: italic;
    letter-spacing: 0.48px;

    font-weight: bold;
    color: #fefeff;
    padding-left: 0.5rem;
    opacity: 1;
  }
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    div {
      max-width: 357px;
    }
    img {
      margin: 20px 0px 20px 0px;
    }
    p {
      text-align: center;
    }
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  width: 100%;
  svg {
    margin-left: 2rem;
    padding: 0px;
    height: 30px;
    width: 30px;
    fill: yellow;
    &:hover {
      fill: #38b6ff;
    }
  }
  div {
    margin: 20px 0px;
  }
  a {
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.1rem;
    padding-right: 1rem;
    color: white;
    &:hover {
      color: yellow;
    }
  }
  @media (max-width: 850px) {
    display: flex;
    align-items: center;
    div:first-of-type {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      a {
        padding: 0px;
      }
      svg {
        margin: 0px;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    a {
      padding: 0px;
      margin: 25px 0px;
    }
  }
`;
