import React from 'react';
import { Link } from 'react-router-dom';
import { DynamicParam } from '../../domain/general';
import { Crumb } from './styles';

interface CrumbsContent {
  text: string;
  url: string;
  params?: DynamicParam;
}

interface BreadCrumbsProps {
  crumbs: Array<CrumbsContent>;
}

const BreadCrumbs = ({ crumbs }: BreadCrumbsProps) => {
  return (
    <Crumb>
      <ul>
        {crumbs.map((crumb: CrumbsContent, index: number) => (
          <li key={`${index}${crumb.text}`}>
            {index !== 0 && '/'}
            <Link to={{
              pathname: crumb.url,
              state: { params: crumb.params }
            }} >{crumb.text}</Link>
          </li>
        ))}
      </ul>
    </Crumb>
  );
};

export default BreadCrumbs;
