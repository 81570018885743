import styled from '@emotion/styled';

export const Back = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Container = styled.div`
  font-family: Mulish;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: 0px;
  width: 100vw;
  align-items: center;
  > h2 {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    color: black;
    margin-bottom: 20px;
  }
  > span {
    font-weight: normal;
    font-size: 1.2rem;
    text-align: center;
    color: black;
    margin-bottom: 45px;
  }
  > form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
  }
`;
