import styled from '@emotion/styled';

export const Content = styled.div`
  margin: 3% auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  justify-items: center;
`;

export const Option = styled.div`
  width: 15%;
  @media (max-width: 500px) {
    width: 100px;
  }
`;

export const Main = styled.div`
  width: 90%;
  display: flex;
  @media (max-width: 500px) {
    width: 100%;
  }
  h2 {
    margin: 0;
    margin-bottom: 2%;
    padding: 0;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: #050a24;
  }
  h5 {
    margin-bottom: 6%;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #050a24;
  }
`;
