import React from 'react';
import Layout from '../../components/Layout';
import { Content, Option, Main } from './styles';
import OptionItem from '../../components/OptionItem';
import ProfileSimple from '../../components/ProfileSimple';
import ProfilePassword from '../../components/ProfilePassword';

export const Home = () => {
  const [selectedOption, setSelectedOption] = React.useState(
    'Dados Cadastrais'
  );

  return (
    <Layout>
      <Content>
        <Option>
          <OptionItem
            name="Dados Cadastrais"
            selected={selectedOption === 'Dados Cadastrais'}
            change={() => setSelectedOption('Dados Cadastrais')}
          />
          <OptionItem
            name="Redefinir Senha"
            selected={selectedOption === 'Redefinir Senha'}
            change={() => setSelectedOption('Redefinir Senha')}
          />
        </Option>
        <Main>
          {selectedOption === 'Redefinir Senha' && <ProfilePassword />}
          {selectedOption === 'Dados Cadastrais' && <ProfileSimple />}
        </Main>
      </Content>
    </Layout>
  );
};

export default Home;
