import styled from '@emotion/styled';

export const Background = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  background-color: #f9fbfd;
  height: 100%;
  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  justify-content: space-around;
  background: radial-gradient(
    62% 31.69% at 53.44% 45.3%,
    rgba(56, 182, 233, 0.4) 9.38%,
    rgba(131, 0, 234, 0.08) 100%
  );
  background-color: #101844;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 2rem 0px;
  max-width: 650px;
`;

export const Icon = styled.img`
  height: 100px;
`;

export const Button = styled.button`
  width: 100%;
  height: 65px;
  background: #0e57b5;
  border-radius: 5px;
  border: #0e57b5;
  color: white;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 2rem 0px;
  &:hover {
    background: #0e57e1;
    cursor: pointer;
  }
`;

export const Svg = styled.svg`
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Mulish;
  h1 {
    font-weight: bold;
    font-size: 3rem;
    color: #050a24;
    margin: 2rem 0px;
  }
  a {
    text-decoration: none;
    color: #0e57b5;
    font-size: 1.1rem;
    font-weight: bold;
  }
  form {
    width: 100%;
    max-width: 70%;
    div:first-of-type {
      margin-bottom: 1rem;
    }
  }
  > p {
    font-size: 1.1rem;
    max-width: 310px;
    text-align: center;
    margin: 3rem 0px;
  }
  @media (max-width: 1000px) {
    max-width: 100vw;
  }
`;
