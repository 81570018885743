import { toast } from 'react-toastify';

type ShowMessageProps = {
  intent: 'success' | 'error' | 'warning';
  message: string;
  toastId?: number;
};

export const showMessage = ({
  intent,
  message,
  toastId = 200,
}: ShowMessageProps) => {
  toast[intent](message, {
    position: 'top-center',
    autoClose: 3000,
    toastId,
  });
};
