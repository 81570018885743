import api from '../../services/api';
import Back from '../../components/Back';
import Layout from '../../components/Layout';
import Checkbox from '../../components/Check';
import { getUser } from '../../services/auth';
import ToolTip from '../../components/ToolTip';
import TextArea from '../../components/TextArea';
import { renderError } from '../../hooks/error';
import { showMessage } from '../../hooks/messages';
import { useParams, useHistory } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import { SelectComponent, OptionsComonent } from '../../components/Select';
import { CompetenciesProps } from '../../domain/Competencies/Competencies';
import { essayCorrectedAdmin, essayReceivedStudent } from '../../hooks/email';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  Background,
  From,
  Title,
  Save,
  Finish,
  Content,
  Info,
  InfoComponent,
  Division,
  Grid,
  Competences,
  CompetencesGrid,
  Score,
  CompetenceItem,
  Total,
  Essay,
  EssayParagraph,
  Comments,
  PostComment,
  Tag,
  AddComments,
  ShowComments,
  CommentCard,
  DeletComment,
  Final,
  ModalFinishCorrection,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from './styles';

interface UserProps {
  id: number;
  firstname?: string;
  lastname?: string;
  name: string;
  email: string;
}

interface ThemeProps {
  id: number;
  name: string;
}

interface EssayGradesProps {
  id: number;
  id_essay: EssayProps;
  id_competency: CompetenciesProps;
  score: number;
  created_at: string;
  updated_at: string;
}

interface ObjectProps {
  id: number;
  name: string;
}

interface ParamTypes {
  id: string;
}

interface EssayProps {
  id: number;
  title: string;
  score: string;
  idUser: UserProps;
  theme: ThemeProps;
  final_comment: string;
  essay_status: number;
  created_at: string;
}
interface ParagraphSimpleProps {
  id: string;
  id_essay: number;
  text: string;
}
interface ParagraphProps {
  id: string;
  id_essay: EssayProps;
  text: string;
}

interface CompetencyProps extends CompetenciesProps {
  initial?: boolean;
  color: string;
  score: number;
  obj: ObjectProps;
  competency: CompetenciesProps;
}

interface CommentProps {
  id: number;
  created_at: string;
  id_paragraph: null | ParagraphSimpleProps;
  id_competency: null | CompetencyProps;
  comment: string;
}

const Correction = () => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const score = [0, 40, 60, 80, 120, 160, 180, 200];
  const [competences, setCompetences] = useState<Array<CompetencyProps>>([]);
  const [grades, setGrades] = useState<Array<EssayGradesProps>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [essay, setEssay] = useState<EssayProps>({
    id: 0,
    title: '',
    score: '',
    final_comment: '',
    essay_status: 2,
    theme: { id: 0, name: '' },
    idUser: {
      id: 0,
      email: '',
      name: '',
    },
    created_at: '',
  });
  const [comments, setComments] = useState<CommentProps>({
    id: 0,
    id_paragraph: null,
    id_competency: null,
    comment: '',
    created_at: '',
  });
  const [allComments, setAllComments] = useState<Array<CommentProps>>([]);
  const [paragraph, setParagraph] = useState<Array<ParagraphProps>>([]);
  const [finalComment, setFinalComment] = useState('');
  const [showParagraph, setShowParagraph] = useState(false);
  const [modalFinish, setModalFinish] = useState(false);
  const [showCompetences, setShowCompetences] = useState(false);

  useEffect(() => {
    try {
      Promise.all([
        api
          .get(`/essays/${id}`)
          .then(({ data }) => {
            if (data.essay_status.status === 'Corrigida') {
              history.push(`/corrected/${data.id}`);
            }
            setEssay(data);
            setFinalComment(data.final_comment);
          })
          .catch((err) => history.push('/home')),

        api.get(`/essay-paragraphs?id_essay=${id}`).then(({ data }) => {
          setParagraph(data);
        }),

        api.get(`/essay-grades?id_essay=${id}`).then(({ data }) => {
          setGrades(data);
        }),

        api.get('/essay-comments').then(({ data }) => {
          setAllComments(
            data.filter(
              (elem: CommentProps) =>
                elem.id_paragraph?.id_essay === parseInt(id)
            )
          );
        }),
      ]);
    } catch (err) {
      renderError(err);
    }
  }, [id, history]);

  useEffect(() => {
    const color = ['#5375CD', '#4AC776', '#FF6F69', '#9E74D4', '#FFAD33'];
    try {
      api.get('/competencies').then((response) => {
        const competenceArray = response.data.map(
          (elem: CompetenciesProps, index: number) => {
            let oldGrades = grades.filter(
              (grade) => grade.id_competency.id === elem.id
            );
            if (oldGrades.length) {
              const item = {
                initial: false,
                name: elem.name,
                obj: oldGrades[0].id_competency,
                color: color[index],
                score: oldGrades[0].score,
                competency: elem,
              };
              return item;
            } else {
              const item = {
                initial: true,
                name: elem.name,
                obj: elem,
                color: color[index],
                score: 0,
                competency: elem,
              };
              return item;
            }
          }
        );
        setCompetences(competenceArray);
      });
    } catch (err) {}
  }, [grades]);

  const getFinalScore = () => {
    let score = 0;
    competences.map((elem: CompetencyProps) => {
      score = score + elem.score;
      return elem;
    });
    return score;
  };

  const getWords = (text: string) => {
    let words = text.replace(/(^\s*)|(\s*$)/gi, '');
    words = words.replace(/[ ]{2,}/gi, ' ');
    words = words.replace(/\n /, '\n');
    return words.split(' ').length;
  };

  const getAllWords = () => {
    const words = paragraph.map((elem: ParagraphProps) => elem.text).join(' ');
    words.replace(/(^\s*)|(\s*$)/gi, '');
    words.replace(/[ ]{2,}/gi, ' ');
    words.replace(/\n /, '\n');
    return words.split(' ').length;
  };

  const getLines = () => {
    const words = getAllWords() / 15;
    return Math.round(words);
  };
  const getDate = () => {
    const date = new Date(essay.created_at);
    return date.toLocaleDateString();
  };

  const changeShowParagraph = (value: boolean) => {
    setShowParagraph(value);
  };
  const changeShowCompetences = (value: boolean) => {
    setShowCompetences(value);
  };

  const changeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComments({ ...comments, comment: event.target.value });
  };

  const changeFinalComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFinalComment(event.target.value);
  };

  const addComment = (e: FormEvent) => {
    e.preventDefault();
    if (!comments.id_competency) {
      showMessage({
        intent: 'error',
        message:
          'Não é possível fazer um  comentário sem selecionar uma competência',
      });
      return;
    }
    if (!comments.id_paragraph) {
      showMessage({
        intent: 'error',
        message: 'É necessário selecionar um paragráfo para fazer o comentário',
      });
      return;
    }
    if (!comments.comment) {
      showMessage({
        intent: 'error',
        message: 'Comentário não pode ser vazio',
      });
      return;
    }
    try {
      api
        .post('/essay-comments', {
          comment: comments.comment,
          location: null,
          id_competency: comments.id_competency.obj.id,
          id_paragraph: comments.id_paragraph,
        })
        .then(() => {
          api.get('/essay-comments').then((response) => {
            const data = response.data;
            setAllComments(
              data.filter(
                (elem: CommentProps) => elem.id_paragraph?.id_essay === essay.id
              )
            );
            showMessage({
              intent: 'success',
              message: 'Comentário criado com sucesso!',
            });
          });
        });
    } catch (err) {
      renderError(err);
    }
  };

  async function verifyGradeExists(
    id_competency: number,
    id_essay: number,
    score: number
  ) {
    if (!id_essay) {
      window.location.reload();
    }
    let query = `essay-grades?id_essay=${id_essay}&id_competency=${id_competency}`;
    try {
      const essayGradesResponse = await api.get(query);
      if (essayGradesResponse.data.length) {
        const essayGradeResponseExist = await api.put(
          `essay-grades/${essayGradesResponse.data[0].id}`,
          {
            score,
          }
        );
        let newGrades = grades.map((grade) => {
          if (
            essayGradeResponseExist.data.id_essay.id === grade.id_essay.id &&
            essayGradeResponseExist.data.id_competency.id ===
              grade.id_competency.id
          ) {
            grade.score = score;
          }
          return grade;
        });
        setGrades(newGrades);
      } else {
        try {
          const newEssayGrade = await api.post(`essay-grades`, {
            id_essay,
            id_competency,
            score,
          });
          grades.push(newEssayGrade.data);
          setGrades(grades);
        } catch (err) {
          renderError(err);
        }
      }
    } catch (err) {
      renderError(err);
    }
  }

  const handleFinishCorrection = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    let checkInitial = competences.filter(
      (competence: CompetencyProps) => competence.initial === false
    );
    if (checkInitial.length === competences.length) {
      if (finalComment && finalComment.length) {
        const teacher = getUser();
        try {
          await api.put(`/essays/${essay.id}`, {
            essay_status: 3,
            final_comment: finalComment,
          });
          await essayCorrectedAdmin({
            teacherName: teacher.name,
            theme: essay.theme.name,
            name: essay.idUser.name,
            id: essay.id,
          });
          await essayReceivedStudent({
            name: essay.idUser.name,
            theme: essay.theme.name,
            email: essay.idUser.email,
            id: essay.id,
          });
          showMessage({
            intent: 'success',
            message: 'Redação corrigida com sucesso!',
          });
          await api.post('/notifies', {
            idSender: teacher.id,
            idReceiver: essay.idUser.id,
            text: `Redação com o tema: ${essay.theme.name} foi corrigida!`,
            read: false,
            params: `/corrected/${essay.id}`,
          });
          showMessage({
            intent: 'success',
            message: 'Notificação enviada com sucesso!',
          });
          history.push('/home');
        } catch (err) {
          renderError(err);
        }
      } else {
        showMessage({
          intent: 'error',
          message: 'É necessário ser realizado um comentário final da redação',
        });
      }
    } else {
      showMessage({
        intent: 'error',
        message: 'Por favor preencha todas as pontuações por competência!',
      });
    }
    setIsLoading(false);
  };

  const handleSaveEssay = (e: FormEvent) => {
    e.preventDefault();
    try {
      api.put(`/essays/${id}`, {
        essay_status: 2,
        final_comment: finalComment,
      });
      showMessage({ intent: 'success', message: 'Redação salva com sucesso!' });
    } catch (err) {
      renderError(err);
    }
  };

  const deleteComment = (e: FormEvent, id: number) => {
    e.preventDefault();
    api
      .delete(`/essay-comments/${id}`)
      .then(() => {
        api
          .get('/essay-comments')
          .then((response) => {
            const data = response.data;
            setAllComments(
              data.filter(
                (elem: CommentProps) => elem.id_paragraph?.id_essay === essay.id
              )
            );
            showMessage({
              intent: 'success',
              message: 'Comentário removido com sucesso!',
            });
          })
          .catch((err) => {
            renderError(err);
          });
      })
      .catch((err) => {
        renderError(err);
      });
  };

  const renderInfo = (title: string, content: string) => {
    return (
      <InfoComponent>
        <label>{title}</label>
        <h3>{content}</h3>
      </InfoComponent>
    );
  };

  const renderParagraph = (number: string, content: string) => {
    return (
      <EssayParagraph key={`${'paragraph-essay ' + number}`}>
        <div>
          <label>{number + 'º Parágrafo'}</label>
          <h2>{`${getWords(content)} Palavras escritas`}</h2>
        </div>
        <h3>{`${'\xa0\xa0\xa0\xa0\xa0' + content}`}</h3>
      </EssayParagraph>
    );
  };

  const renderComment = (elem: CommentProps) => {
    const commentParagraph = paragraph
      .map((paragraph: ParagraphProps) => paragraph.id)
      .sort()
      .indexOf(elem.id_paragraph ? elem.id_paragraph.id : '0');

    return (
      <CommentCard key={`${elem.created_at}`}>
        <div>
          <Tag
            paragraph={{
              name: `Parágrafo ${commentParagraph + 1}`,
              color: '#6FCAFF',
            }}
            competency={{
              name: elem.id_competency?.name || '',
              color: competences.filter(
                (comp: CompetencyProps) =>
                  comp.name === elem.id_competency?.name
              )[0]?.color,
            }}
          />
          <DeletComment onClick={(e: FormEvent) => deleteComment(e, elem.id)} />
        </div>
        <p>{elem.comment}</p>
      </CommentCard>
    );
  };

  const handleSetCheckBox = (compenteceIndex: number, index: number) => {
    setCompetences((prevState: Array<CompetencyProps>) => {
      prevState[compenteceIndex].score = score[index];
      prevState[compenteceIndex].initial = false;
      return [...prevState];
    });
  };
  return (
    <Layout>
      <ModalFinishCorrection show={modalFinish}>
        <ModalHeader>
          <div>Finalizar Correção</div>
          <DeletComment
            onClick={() => setModalFinish(!modalFinish)}
          ></DeletComment>
        </ModalHeader>
        <ModalBody>
          <p>
            Após o envio da correção não será possível fazer novas alterações.
          </p>
          Deseja finalizar sua correção?
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={() => {
              setModalFinish(!modalFinish);
            }}
          >
            não
          </button>
          <button
            type="button"
            onClick={(e: FormEvent) => {
              handleFinishCorrection(e);
            }}
            disabled={isLoading}
          >
            sim
          </button>
        </ModalFooter>
      </ModalFinishCorrection>
      {essay && (
        <Background>
          <From>
            <Title>
              <Back />
              <div>
                <div>
                  <BreadCrumbs
                    crumbs={[
                      { text: 'Redações', url: '/home' },
                      { text: 'Pendentes', url: '/home', params: { type: 'Pendentes' } },
                      { text: 'Corrigir Redação', url: '' },
                    ]}
                  ></BreadCrumbs>
                </div>
                <div>
                  <Save onClick={(e: FormEvent) => handleSaveEssay(e)}>
                    Salvar
                  </Save>
                  <Finish
                    type="button"
                    onClick={() => {
                      setModalFinish(true);
                    }}
                  >
                    Finalizar correção
                  </Finish>
                </div>
              </div>
              <h1>Correção</h1>
            </Title>
            <Content>
              <Info>
                {renderInfo('Enviada em', getDate())}
                {renderInfo('Tema', essay.theme.name)}
                {renderInfo('Título', essay.title)}
                {renderInfo('Enviada por', essay.idUser?.name)}
                <Division />
                {renderInfo('Linhas Aproximadas', getLines().toString())}
                {renderInfo('Palavras Escritas', getAllWords().toString())}
                {renderInfo('parágrafos', paragraph.length.toString())}
              </Info>
              <Grid>
                <div>
                  <Essay>
                    {paragraph.map((elem: ParagraphProps, index: number) =>
                      renderParagraph(`${index + 1}`, elem.text)
                    )}
                  </Essay>
                  <Competences>
                    <h3>Pontuação por competência</h3>
                    <CompetencesGrid>
                      <Score>
                        {score.map((elem: number, index: number) => (
                          <div key={`${elem + index} `}>{elem}</div>
                        ))}
                      </Score>
                      <div>
                        {competences.map((competence, compenteceIndex) => (
                          <div key={competence.name}>
                            <CompetenceItem color={competence.color}>
                              <ToolTip
                                name={competence.name}
                                competency={competence.competency}
                                background={competence.color}
                              />
                              <div>
                                {score.map((value: number, index: number) => {
                                  return (
                                    <Checkbox
                                      key={`checkbox${value}`}
                                      isChecked={
                                        !competence.initial &&
                                        competence.score === score[index]
                                      }
                                      name={competence.score.toString()}
                                      text=""
                                      change={() => {
                                        verifyGradeExists(
                                          competence.obj.id,
                                          parseInt(id),
                                          value
                                        ).then(() => {
                                          handleSetCheckBox(
                                            compenteceIndex,
                                            index
                                          );
                                        });
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </CompetenceItem>
                          </div>
                        ))}
                        <Total>
                          <h3>Nota Total</h3>
                          <div>{getFinalScore()}</div>
                        </Total>
                      </div>
                    </CompetencesGrid>
                  </Competences>
                </div>
                <Comments>
                  <AddComments>
                    <h3>Comentários</h3>
                    <p>
                      Selecione a competência e os parágrafos para pontuar os
                      erros e acertos desta redação.
                    </p>
                    <SelectComponent
                      setShow={changeShowParagraph}
                      show={showParagraph}
                      isGreyScale={comments.id_paragraph ? false : true}
                      value={
                        comments.id_paragraph
                          ? `Parágrafo ${
                              paragraph
                                .sort()
                                .map(
                                  (paragraph: ParagraphProps) =>
                                    paragraph.id === comments.id_paragraph?.id
                                )
                                .indexOf(true) + 1
                            }`
                          : 'Selecione o parágrafo comentado'
                      }
                      label="Parágrafo"
                      content={
                        <div>
                          <OptionsComonent
                            isShow={showParagraph}
                            content={paragraph
                              .sort()
                              .map(
                                (paragraph: ParagraphProps, index: number) => (
                                  <button
                                    key={`paragraph ${paragraph.id}`}
                                    onClick={(e: FormEvent) => {
                                      e.preventDefault();
                                      const changeParagraph = {
                                        ...paragraph,
                                        id_essay: parseInt(paragraph.id),
                                      };

                                      setComments({
                                        ...comments,
                                        id_paragraph: changeParagraph,
                                      });
                                      setShowParagraph(!showParagraph);
                                    }}
                                  >
                                    {`Parágrafo ${index + 1}`}
                                  </button>
                                )
                              )}
                          ></OptionsComonent>
                        </div>
                      }
                    ></SelectComponent>
                    <SelectComponent
                      setShow={changeShowCompetences}
                      show={showCompetences}
                      isGreyScale={comments.id_competency ? false : true}
                      value={
                        comments.id_competency
                          ? comments.id_competency.name
                          : 'Selecione a competência analisada'
                      }
                      label="Competência"
                      content={
                        <div>
                          <OptionsComonent
                            isShow={showCompetences}
                            content={competences
                              .map((elem: CompetencyProps) => elem.name)
                              .map((competence: string, index: number) => (
                                <button
                                  key={`competence ${index}`}
                                  onClick={(e: FormEvent) => {
                                    e.preventDefault();
                                    setComments({
                                      ...comments,
                                      id_competency: competences[index],
                                    });
                                    setShowCompetences(!showCompetences);
                                  }}
                                >
                                  {competence}
                                </button>
                              ))}
                          ></OptionsComonent>
                        </div>
                      }
                    ></SelectComponent>

                    <TextArea
                      isGreyScale={comments.comment.length ? false : true}
                      placeholder="Escreva aqui sua observação sobre a redação"
                      labelText="digite o seu comentário"
                      value={comments.comment}
                      change={changeComment}
                    />
                    <PostComment onClick={(e: FormEvent) => addComment(e)}>
                      Adicionar comentário
                    </PostComment>
                  </AddComments>
                  <ShowComments>
                    {allComments.length
                      ? allComments.map((elem: CommentProps) =>
                          renderComment(elem)
                        )
                      : 'Você ainda não inseriu nenhum comentário.'}
                  </ShowComments>
                </Comments>
              </Grid>
            </Content>
            <Final>
              <TextArea
                isGreyScale={finalComment && finalComment.length ? false : true}
                placeholder="Escreva aqui suas observações finais sobre esta redação;"
                labelText="Comentário final"
                change={changeFinalComment}
                value={finalComment}
              />
            </Final>
          </From>
        </Background>
      )}
    </Layout>
  );
};

export default Correction;
