import styled from '@emotion/styled';

interface ItemProps {
  selected?: boolean;
}

export const Item = styled.div<ItemProps>`
  width: 100%;
  margin-bottom: 10%;
  margin-left: 1%;
  padding-left: 5%;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 23px;
  color: ${({ selected }) => (selected ? '#000' : '#9aa0ba')};
  border-left: ${({ selected }) => (selected ? '#0E57B5' : 'none')} solid;
  &:hover {
    cursor: pointer;
  }
`;
