import React from 'react';
import { Select, Toggle, Options } from './styles';

interface SelectProps {
  show: boolean;
  isGreyScale: boolean;
  setShow: (value: boolean) => void;
  value: string;
  label: string;
  content: any;
}

interface OpstionsProps {
  isShow: boolean;
  content: any;
}

export const SelectComponent = ({
  show,
  isGreyScale,
  setShow,
  value,
  label,
  content,
}: SelectProps) => {
  return (
    <Select show={show} isGreyScale={isGreyScale}>
      <label>{label}</label>
      <button
        type="button"
        onClick={() => {
          return setShow(!show);
        }}
      >
        {value}
        <Toggle show={show}>
          <path d="M19.532,9.242a1.247,1.247,0,0,0-1.758,0L13.3,13.657,8.882,9.242a1.248,1.248,0,1,0-1.758,1.771L12.411,16.3a1.247,1.247,0,0,0,1.771,0l5.35-5.288a1.247,1.247,0,0,0,0-1.771Z"></path>
        </Toggle>
      </button>
      {content}
    </Select>
  );
};

export const OptionsComonent = ({ isShow, content }: OpstionsProps) => {
  return <Options isShow={isShow}>{content}</Options>;
};
