import React, { useEffect, useState } from 'react';
import { Item, Info, Status } from './styles';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

interface TableItemProps {
  date: string;
  author: string;
  theme: string;
  status: string;
  idEssay: number;
  route: string;
}

interface ButtonProps {
  background: string;
  color: string;
}

const TableItem = ({
  date,
  author,
  theme,
  status,
  idEssay,
  route,
}: TableItemProps) => {
  const history = useHistory();
  const [buttonProps, setButtonProps] = useState<ButtonProps>({
    background: '',
    color: '',
  });
  const handleSetButtonProps = (status: string) => {
    switch (status) {
      case 'Corrigida':
        return setButtonProps({ background: '#CEEFE0', color: '#0AAE65' });
      case 'Corrigindo':
        return setButtonProps({ background: '#FCEDD4', color: '#F1A728' });
      default:
        return setButtonProps({ background: '#FFF7CC', color: '#FFD800' });
    }
  };

  useEffect(() => {
    handleSetButtonProps(status);
  }, [status]);
  return (
    <Item>
      <Info>
        <div>
          <label>Tema</label>
          <span>{theme}</span>
        </div>
        <div>
          <label>Feita por</label>
          <span>{author}</span>
        </div>
        <div>
          <label>Enviado em</label>
          <span>
            <Moment format="DD/MM/YYYY">{date}</Moment>
          </span>
        </div>
        <div
          onClick={() => {
            history.push(`${route}/${idEssay}`);
          }}
        >
          <Status background={buttonProps.background} color={buttonProps.color}>
            <div></div>
            <div>{status}</div>
          </Status>
        </div>
      </Info>
    </Item>
  );
};

export default TableItem;
