import styled from '@emotion/styled';

export const Container = styled.div`
  width: 99%;
  margin-bottom: 5%;
`;

export const Title = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  margin: 3% 0;

  h2 {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 1%;
  }

  hr {
    width: 10%;
    margin: 0;
    border-color: #6fcaff;
    border-radius: 0.5rem;
  }
`;

export const FirstComment = styled.div`
  display: flex;
  width: 90%;
`;

export const SecondComment = styled.div`
  display: flex;
  width: 90%;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 10%;
`;

export const Frame = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: #6fcaff;
  display: flex;
  margin-right: 20px;
  svg {
    margin: 28px;
    fill: #f9fbfd;
    width: 80px;
    height: 80px;
  }
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const MadeBy = styled.div`
  font-family: Mulish, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: justify;
  color: #050a24;
  margin: 15px 0;
`;

export const CreateComment = styled.div``;

export const Text = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
  text-align: justify;
  color: #050a24;
`;

export const Button = styled.div`
  display: flex;
  margin-left: 10%;
  width: 90%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  button {
    border: none;
    background-color: inherit;
  }
`;

export const ButtonDocument = styled.div`
  width: 10rem;
  height: 3rem;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  background: #d7effd;
  border-radius: 0.5rem;
  border: none;
  color: #38b6ff;
  font-size: 1rem;
  margin-right: 0.9rem;
  &:not(:last-child) {
    margin-right: 4.5rem;
  }
  cursor: pointer;
  span {
    margin-right: 3%;
  }
  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.5rem;
    opacity: 1;
    fill: #38b6ff;
  }

  &:hover {
    background: #0e57b5;
    color: white;
    svg {
      fill: white;
    }
  }
`;
