import styled from '@emotion/styled';

interface buttonProps {
  isChecked: boolean;
}
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  justify-items: center;
  margin: 10px 0px;
  width: 100%;

  label {
    width: 60%;
    line-height: initial;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    position: relative;
    color: black !important;
    letter-spacing: 0.8px;
    cursor: pointer;
    margin: 0px;
    text-transform: none;
    padding: 0px;
  }
`;

export const Button = styled.button<buttonProps>`
  cursor: pointer;
  margin: 0px;
  width: 25px;
  height: 25px;
  background: ${({ isChecked }) => (isChecked ? '#6FCAFF' : '#fefeff')};
  border: ${({ isChecked }) => (isChecked ? 'none' : '1px solid #9aa0ba')};
  border-radius: 5px;

  svg {
    width: 20px;
    height: 20px;
    transform: translateX(-2px);
    fill: white;
  }
`;
