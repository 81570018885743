import React from 'react';
import Button from '../../components/ButtonComponent';
import { Input, InputPassord } from '../../components/Input';
import LoginImg from '../../images/login.svg';
import LogoImg from '../../images/logo.png';
import { useForm } from 'react-hook-form';
import { authenticate, isAuth } from '../../services/auth';
import Loading from '../../components/Loading';
import { showMessage } from './../../hooks/messages';
import { useHistory } from 'react-router-dom';
import { Background, Image, Img, Icon, Content } from './styles';

type FormValues = {
  identifier: string;
  password: string;
};

const Login = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { identifier: '', password: '' },
  });

  const history = useHistory();

  React.useEffect(() => {
    if (isAuth()) {
      history.push('home');
    }
  }, [history]);

  const submit = async (data: FormValues) => {
    setIsLoading(true);
    const response = await authenticate(data);
    response &&
      showMessage({ intent: response.status, message: response.message });
    setIsLoading(false);
    if (response?.status === 'success') {
      setTimeout(() => history.push('home'), 2000);
    }
    return;
  };

  return (
    <Background>
      <Image>
        <Img src={LoginImg} />
      </Image>
      <Content>
        <Icon src={LogoImg} />
        <h1>Bem vindo!</h1>
        <form onSubmit={handleSubmit(submit)}>
          <Input
            placeholder="Informe seu email de login"
            label="Email"
            type="email"
            {...register('identifier', {
              required: 'Email é um campo obrigatório',
            })}
            error={errors.identifier?.message}
          />
          <InputPassord
            placeholder="Qual sua senha?"
            label="Senha"
            {...register('password', {
              required: 'Senha é um campo obrigatório',
            })}
            error={errors.password?.message}
          />
          <Button type="submit" content={isLoading ? <Loading /> : 'Entrar'} />
        </form>
        <a href="/forgot-password">Esqueci minha senha</a>
        <p>
          Ainda não faz parte da comunidade Aluno Ensina?
          <a href="/signup"> Comece por aqui</a>
        </p>
      </Content>
    </Background>
  );
};

export default Login;
