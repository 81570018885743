import styled from '@emotion/styled';

interface ImageProps {
  background: string;
}
interface InterfaceNotify {
  read?: boolean;
}

export const Container = styled.div<InterfaceNotify>`
  width: 100%;
  flex-direction: column;
  height: 70px;
  padding: 10px 0;
  padding-bottom: 10px;
  background-color: ${({ read }) => (read ? '#EEF8FF' : 'white')};
`;

export const Date = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: Mulish;
  font-size: 0.5rem;
  color: #9aa0ba;
  margin-right: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  justify-content: space-between;
  align-items: space-between;
`;

export const Image = styled.div<ImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  margin-right: 7%;
  background-color: ${({ background }) => (background ? background : 'white')};
`;

export const Message = styled.span`
  width: 85%;
  label {
    font-weight: bold;
  }
  font-family: Mulish;
  font-style: normal;
  font-size: 0.8rem;
  color: #000000;
`;
