import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(52, 51, 75, 0.08)',
  overlayShadow: '0 0 15px rgba(52, 51, 75, 0.08)',
  borderContext: '1px solid #D5D5D5',
  colors: {
    primary: '#4C7BD9',
    grey: '#A5B1C2',
    lightGrey: '#A5B1C2',
    background: '#F6F7FB',
    backgroundContext: '#F1F2F7',
    white: '#FFFFFF',
    groupedSearch: '#106BA3',
    individualSearch: '#4C7BD9',
    evaluation: '#7157D9',
    finances: '#9BBF30',
    assets: '#32C183',
    dark: '#34334B',
    warning: '#F55656',
    backgroundOpacity: 'rgba(52, 51, 75, 0.44)',
    pending: '#F29D49',
  },
};

const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 16px;
    font-family: 'Mulish', sans-serif;
    @media screen and (max-width: 1400px) {
      font-size: 14px;
    }
  }

  * {
    font-family: 'Mulish', sans-serif;
    font-weight: normal;
  }

  body {
    font-family: 'Mulish', sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: 0.6px;
    font-weight: normal;
  };
`;
export default GlobalStyle;
