import styled from '@emotion/styled';
export const Crumb = styled.div`
  width: 100%;
  ul {
    margin: 1.5rem 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    letter-spacing: 0.44px;
    color: #9298b5;
    font-weight: bold;
    font-size: 1.1rem;

    li {
      width: max-content;
      display: flex;
      flex-direction: row;
      font-weight: bold;
    }
    a {
      margin: 0px 0.5rem 0px 0.3rem;
      text-decoration: none;
      font-weight: bold;
      text-transform: capitalize;
      color: #9298b5;
    }
    li:hover {
      color: #050a24;

      a {
        color: #050a24;
      }
    }
  }
`;
