import styled from '@emotion/styled';

interface CompetenceProps {
  color: string;
}
interface GenericTag {
  name: string;
  color: string;
}
interface ModalProps {
  show: boolean;
}
interface TagProps {
  paragraph: GenericTag;
  competency: GenericTag;
}

export const Background = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f9fbfd;
`;
export const From = styled.form`
  width: 100%;
  max-width: 1200px;
`;
export const Title = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    div {
      width: max-content;
    }
  }
  h1 {
    text-transform: uppercase;
    font-size: 2rem;
  }
  h1:before {
    width: 9rem;
    height: 4px;
    content: '';
    position: absolute;
    color: white;
    background-color: #6fcaff;
    transform: translateY(40px);
  }
`;

export const Save = styled.button`
  text-transform: uppercase;
  border: none;
  color: #0e57b5;
  background: none;
  margin-right: 0.5rem;
  padding: 1rem 2rem;
  font-weight: bold;
  border-radius: 5px;
`;
export const Finish = styled.button`
  text-transform: uppercase;
  border: none;
  color: #fefeff;
  font-weight: bold;
  background-color: #0e57b5;
  padding: 1rem 2rem;
  width: max-content;
  border-radius: 5px;
`;
export const Content = styled.div``;

export const Info = styled.div`
  margin: 2rem 0px;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0px;
  background: #fefeff;
  box-shadow: 0px 12px 25px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  div {
    height: max-content;
    display: flex;
  }
`;

export const InfoComponent = styled.div`
  margin: 10px 0.8rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  label {
    text-align: left;
    font-size: 0.7rem;
    letter-spacing: 0.7px;
    color: #6fcaff;
    text-transform: uppercase;
  }

  h3 {
    text-align: left;
    width: max-content;
    letter-spacing: 0.8px;
    font-size: 0.8rem;
    max-width: 255px;
    margin: 0px;
    margin-top: 0.5rem;
    color: #050a24;
    font-weight: normal;
  }
`;
export const Division = styled.div`
  ::before {
    content: '';
    position: absolute;
    border: 0.5px solid #9aa0ba;
    margin: 0.9rem 0px;
    height: 40px;
    transform: translateY(-10px);
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;
`;
export const Essay = styled.div`
  background: #fefeff;
  border-radius: 5px;
  box-shadow: 0px 12px 25px 5px rgba(0, 0, 0, 0.05);
  padding: 1rem;
`;
export const Competences = styled.div`
  background: #fefeff;
  border-radius: 5px;
  box-shadow: 0px 12px 25px 5px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  margin-top: 2rem;
  > h3 {
    margin-bottom: 1.5rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px;
    text-transform: uppercase;
    color: #050a24;
  }
`;
export const CompetencesGrid = styled.div`
  > div {
    > div {
      height: 50px;
      align-items: center;
    }
    > div:nth-of-type(even) {
      background-color: #f9fdff;
    }
  }
  > div:first-of-type {
    height: 50px;
    background-color: #f9fdff;
    border-radius: 5px 5px 0px 0px;
    width: 80%;
    margin-left: auto;
    div {
      font-weight: bold;
      background-color: transparent;
      padding: 0px;
    }
  }
`;

export const Total = styled.div`
  display: flex;
  border-radius: 0px 0px 5px 5px;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-weight: bold;
    width: 20%;
    font-size: 1rem;
    margin: 0px;
  }
  div {
    font-weight: bold;
    font-style: normal;
    line-height: 20px;
    width: 55px;
    text-align: center;
    padding: 1rem;
  }
  div:first-of-type {
    margin-right: 0.8rem;
  }
`;

export const Score = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  margin-top: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #050a24;
  div {
    display: flex;
  }
`;

export const CompetenceItem = styled.div<CompetenceProps>`
  display: flex;
  align-items: center;
  position: relative;
  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0px;
    font-style: normal;
    line-height: 20px;
    text-transform: capitalize;
    color: ${({ color }) => color};
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 80%;
    justify-items: center;
    height: 100%;
    margin: 0px;
    div {
      width: min-content;
    }
  }
  span {
    width: 20%;
  }
`;

export const EssayParagraph = styled.div`
  margin-bottom: 1.5rem;
  div {
    display: flex;
    box-shadow: none;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px;
    margin-bottom: 1rem;
  }
  label {
    margin-top: 0.5rem;
    font-weight: bold;
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    opacity: 1;
    z-index: 2;
    color: #38b6ff;
  }
  h2 {
    margin-top: 0.5rem;
    padding-left: 0.8rem;
    font-weight: bold;
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    opacity: 1;
    z-index: 2;
    color: #9aa0ba;
  }
  h3 {
    text-align: justify;
    background-color: transparent;
    resize: none;
    text-align: bottom;
    outline: none;
    font-size: 1.2rem;
    font-weight: normal;
    width: 100%;
    min-height: 150px;
    border: none;
    letter-spacing: 0.8px;
    border-radius: 8px;
    color: #050a24;
    z-index: 1;
    &:focus-visible {
      border-color: #38b6ff;
    }
  }
`;

export const Comments = styled.div`
  background: #fefeff;
  border-radius: 5px;
  box-shadow: 0px 12px 25px 5px rgba(0, 0, 0, 0.05);
  padding: 1rem;

  h3 {
    margin-top: 0px;
    margin-bottom: 1rem;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #050a24;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 0.95rem;
    line-height: 20px;
    color: #000000;
  }
`;
export const AddComments = styled.div`
  h3 {
    font-weight: bold;
  }
  div {
    margin-top: 1.5rem;
    > div {
      margin: 0px;
    }
    textarea {
      width: 100%;
    }
  }
`;
export const PostComment = styled.button`
  text-transform: uppercase;
  border: none;
  color: #fefeff;
  font-weight: bold;
  background-color: #0e57b5;
  padding: 1rem 2rem;
  width: max-content;
  border-radius: 5px;
  width: 100%;
  margin: 1.5rem 0px;
`;
export const ShowComments = styled.div`
  color: #9aa0ba;
  text-align: center;
  font-size: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;
  /* max-height: 60%;
position: absolute;
width: 358px; */
`;
export const CommentCard = styled.div`
  border: 0.5px solid #9aa0ba;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.8rem;
  margin-bottom: 1rem;
  text-align: left;
  > div {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  p {
    font-size: 1rem;
  }
`;
export const Tag = styled.div<TagProps>`
  padding: 0.5rem 0px;
  ::before {
    background: #e2f4ff;
    padding: 0.5rem;
    border-radius: 5px;
    margin-right: 0.5rem;
    content: ${({ paragraph }) => `"${paragraph.name}"`};
    font-weight: bold;
    font-size: 0.85rem;
    text-transform: capitalize;
    line-height: 15px;
    color: ${({ paragraph }) => `${paragraph.color}`};
  }
  ::after {
    background: ${({ competency }) => competency.color + '50'};
    padding: 0.5rem;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.85rem;
    text-transform: capitalize;
    line-height: 15px;
    color: ${({ competency }) => `${competency.color}`};
    content: ${({ competency }) => `"${competency.name}"`};
  }
`;
export const DeletComment = styled.button`
  height: 25px;
  width: 25px;
  background: none;
  border: none;
  transform: scale(0.7);
  margin: 0px;
  margin-top: 0.6rem;
  ::before {
    content: '';
    background-color: #9aa0ba;
    width: 30px;
    height: 3px;
    position: absolute;
    transform: translateX(-17px) rotate(45deg) translateY(-3px);
    border-radius: 5px;
  }
  ::after {
    content: '';
    background-color: #9aa0ba;
    width: 30px;
    height: 3px;
    position: absolute;
    transform: translateX(-13px) rotate(-45deg) translateY(-3px);
    border-radius: 5px;
  }
`;

export const Final = styled.div`
  margin: 2rem 0px;
  display: flex;
  padding: 1rem;
  background: #fefeff;
  box-shadow: 0px 12px 25px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  div {
    display: flex;
    width: 100%;
  }
`;

export const ModalFinishCorrection = styled.div<ModalProps>`
  width: 600px;
  height: 300px;
  position: absolute;
  background-color: white;
  margin-left: calc(50% - 20rem);

  margin-top: 30vh;
  border-radius: 10px;
  flex-direction: column;
  padding: 30px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  z-index: 3;
`;

export const ModalHeader = styled.div`
  display: flex;
  height: 12vh;
  justify-content: space-between;
  font-family: Mulish;
  color: #000000;
  div {
    font-size: 2rem;

    font-weight: bold;
  }
`;

export const ModalBody = styled.div`
  font-family: Mulish;
  font-size: 1rem;
  line-height: 20px;
  color: #000000;
  height: 10vh;
`;

export const ModalFooter = styled.div`
  display: flex;
  height: 8vh;
  justify-content: flex-end;

  > button {
    width: 138px;
    height: 50px;
    margin: 0 10px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    text-transform: uppercase;
  }

  > button:first-of-type {
    border-radius: 5px;
    background-color: white;
    color: #0e57b5;
    &:hover {
      background: #cfddf0;
    }
  }
  > button:not(:first-of-type) {
    background: #0e57b5;
    color: white;
    border-radius: 5px;

    &:hover {
      background: #3e79c4;
    }
  }
`;
