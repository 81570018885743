import React, { useState } from 'react';
import { CompetenciesProps } from '../../domain/Competencies/Competencies';
import { Container } from './styles';

export type ToolTipProps = {
  name: string;
  background: string;
  competency: CompetenciesProps;
};

const ToolTip = ({ name, background, competency }: ToolTipProps) => {
  const [show, setShow] = useState(false);
  const showToolTip = (prop: boolean) => {
    setShow(prop);
  };

  return (
    <span
      onMouseEnter={() => showToolTip(true)}
      onMouseLeave={() => showToolTip(false)}
    >
      <h3>{name}</h3>
      {show && (
        <Container background={background}>
          <div></div>
          <div>
            <h4> Aspectos Positivos</h4>
            <p>{competency.positive_message}</p>
            <h4> Aspectos negativos</h4>
            <p>{competency.negative_message}</p>
          </div>
        </Container>
      )}
    </span>
  );
};

export default ToolTip;
