import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 1200px;
  width: 40%;
  display: flex;
  flex-direction: column;
  margin: 0 2%;
  @media (max-width: 500px) {
    width: 100%;
  }

  h2 {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }

  h5 {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  }
`;
