import React from 'react';
import { Item } from './styles';

interface OptionItemProps {
  name: string;
  selected: boolean;
  change: (name: string) => void | any;
}

const OptionItem = ({ name, selected, change }: OptionItemProps) => {
  return (
    <Item onClick={() => change(name)} selected={selected}>
      {name}
    </Item>
  );
};

export default OptionItem;
