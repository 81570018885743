import React from 'react';
import Button from '../../components/ButtonComponent';
import { Input } from '../../components/Input';
import { Back } from '../../components/BackComponent';
import { Context, Container, Cancel } from './styles';
import { forgetPassword } from '../../services/auth';
import { showMessage } from '../../hooks/messages';
import { useForm } from 'react-hook-form';
import Loading from '../../components/Loading';
import { useHistory } from 'react-router-dom';

type FormValues = {
  email: string;
};

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { email: '' },
  });
  const history = useHistory();

  const submit = async (data: FormValues) => {
    setIsLoading(true);
    const email = await forgetPassword(data.email);
    email && showMessage({ intent: email.status, message: email.message });
    setIsLoading(false);
    if (email?.status === 'success') {
      setTimeout(() => history.push('/'), 2000);
    }
    return;
  };

  return (
    <Context>
      <Back />
      <Container>
        <h2>Informe seu email para a recuperação de sua senha</h2>
        <p>
          Você receberá no seu email um link para a definição de uma nova senha.
        </p>
        <form onSubmit={handleSubmit(submit)}>
          <Input
            placeholder="Digite aqui seu email"
            label="Email"
            type="email"
            {...register('email', {
              required: 'Email é um campo obrigatório',
            })}
            error={errors.email?.message}
          />
          <Button
            type="submit"
            content={isLoading ? <Loading /> : 'Recuperar senha'}
          />
        </form>
        <Cancel href="/">Cancelar</Cancel>
      </Container>
    </Context>
  );
};

export default ForgotPassword;
