import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import OptionItem from '../../components/OptionItem';
import TableItem from '../../components/TableItem';
import api from '../../services/api';
import { getUser } from '../../services/auth';
import { renderError } from '../../hooks/error';
import {
  Content,
  Option,
  Main,
  TableHeader,
  TableBody,
  PaginationItem,
  NonIdealState,
} from './styles';
import { useLocation } from 'react-router';
import { DynamicParam } from '../../domain/general';

interface EssayProps {
  id: number;
  created_at: string;
  created_by: string;
  title: string;
  idUser: {
    name: string;
  };
  theme: string;
  essay_status: { status: string };
}

const Home = () => {
  const location = useLocation();
  const option  = location.state as { params: DynamicParam }
  const [selectedOption, setSelectedOption] = useState(option?.params?.type ?? 'Pendentes');
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(8);
  const [pages, setPages] = useState([] as number[]);
  const [essays, setEssays] = useState<EssayProps[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLimit(8);
    let userId = getUser().id;
    let query = '';
    selectedOption === 'Pendentes'
      ? (query = `/essays?_start=${
          (currentPage - 1) * limit
        }&_limit=${limit}&_sort=id:asc&id_teacher=${userId}&essay_status.status_ne=Corrigida`)
      : (query = `/essays?_start=${
          (currentPage - 1) * limit
        }&_limit=${limit}&_sort=id:asc&id_teacher=${userId}&essay_status.status_eq=Corrigida`);

    api
      .get(query)
      .then((response) => {
        setEssays(response.data);
      })
      .catch((err) => {
        renderError(err);
      });
    selectedOption === 'Pendentes'
      ? (query = `/essays/count?essay_status.status_ne=Corrigida&id_teacher=${userId}`)
      : (query = `/essays/count?essay_status.status_eq=Corrigida&id_teacher=${userId}`);

    api
      .get(query)
      .then((response) => {
        setTotal(response.data);
        const TotalPages = Math.ceil(total / limit);
        const arrayPages = [];
        for (let i = 1; i <= TotalPages; i++) {
          arrayPages.push(i);
        }
        setPages(arrayPages);
      })
      .catch((err) => {
        renderError(err);
      });
  }, [currentPage, limit, total, selectedOption]);

  const handleChange = (name: string) => {
    setSelectedOption(name);
  };

  return (
    <Layout>
      <Content>
        <Option>
          <OptionItem
            name="Pendentes"
            selected={selectedOption === 'Pendentes'}
            change={handleChange}
          />
          <OptionItem
            name="Concluídas"
            selected={selectedOption === 'Concluídas'}
            change={handleChange}
          />
        </Option>
        <Main>
          <h2>Seja bem-vindo, professor {getUser().name}</h2>
          <h5>Essas são as redações que seus alunos enviaram.</h5>
          <TableHeader>
            <p>
              Exibindo {limit > total ? total : limit} de {total} redações
              pendentes
            </p>
            <div>
              {currentPage > 1 && (
                <PaginationItem onClick={() => setCurrentPage(currentPage - 1)}>
                  {'<'}
                </PaginationItem>
              )}
              {pages.map((num) => {
                return (
                  <PaginationItem
                    onClick={() => setCurrentPage(num)}
                    isSelect={num === currentPage}
                    key={num}
                  >
                    {num}
                  </PaginationItem>
                );
              })}
              {currentPage < pages.length && (
                <PaginationItem onClick={() => setCurrentPage(currentPage + 1)}>
                  {'>'}
                </PaginationItem>
              )}
            </div>
          </TableHeader>
          {!essays.length && (
            <NonIdealState>{`Não existe redação com status ${selectedOption}`}</NonIdealState>
          )}
          <TableBody>
            {essays.map((essay) => (
              <TableItem
                key={essay.created_at}
                theme={essay.title}
                author={essay.idUser?.name}
                date={essay.created_at}
                status={
                  essay.essay_status ? essay.essay_status.status : 'A Corrigir'
                }
                idEssay={essay.id}
                route={
                  selectedOption === 'Pendentes' ? '/correction' : '/corrected'
                }
              />
            ))}
          </TableBody>
        </Main>
      </Content>
    </Layout>
  );
};

export default Home;
