import styled from '@emotion/styled';

export const Container = styled.div`
  font-family: Mulish;

  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 12rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h2 {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    color: black;
    margin-bottom: 20px;
  }
  > span {
    font-weight: normal;
    font-size: 1.2rem;
    text-align: center;
    color: black;
    margin-bottom: 45px;
  }

  > form {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    width: 400px;
  }
`;
