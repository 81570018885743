import styled from '@emotion/styled';

export const Container = styled.button`
  width: 100%;
  height: 65px;
  background: #0e57b5;
  border-radius: 10px;
  border: #0e57b5;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin: 2rem 0px;
  &:hover {
    background: #0e57e1;
    cursor: pointer;
  }
`;
