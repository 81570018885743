import styled from '@emotion/styled';

interface ToolTipProps {
  background: string;
}

export const Container = styled.div<ToolTipProps>`
  width: 130px;
  height: 40px;
  position: absolute;
  color: transparent;

  animation: tooltipAnimation 0.6s ease;
  > div:first-of-type {
    display: flex;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    margin-left: 18px;
    transform: rotate(45deg);
    background-color: ${({ background }) => background};
  }

  > div:not(:first-of-type) {
    z-index: 0;
    width: 600px;
    border-radius: 10px;
    margin-top: 20px;
    background-color: ${({ background }) => background};
    height: 200px;
    z-index: 10;
    position: absolute;
    display: flex;
    padding: 10px;
    flex-direction: column;

    h4 {
      font-weight: bold;
      color: white;
      font-family: Mulish;
    }
    p {
      font-family: Mulish;
      color: white !important;
      font-style: normal;
      padding-bottom: 20px;
      font-size: 14px;
      line-height: 18px;
    }
  }
  @keyframes tooltipAnimation {
    0% {
      opacity: 0;
      z-index: 10;
    }
    100% {
      z-index: 10;
      opacity: 1;
    }
  }
`;
