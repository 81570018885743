import styled from '@emotion/styled';

interface SelectProps {
  show: boolean;
  isGreyScale?: boolean;
}

interface OptionsProps {
  isShow: boolean;
}

export const Select = styled.div<SelectProps>`
  position: relative;
  cursor: pointer;

  > label {
    font-weight: bold;
    margin-left: 0px;
    position: absolute;
    margin-top: 0.5rem;
    padding-left: 0.8rem;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    opacity: 1;
    z-index: 2;
    color: #38b6ff;
  }

  > button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 72px;
    background: transparent;
    color: #9298b5;
    border: 0.5px solid #9aa0ba;
    box-shadow: ${({ show }) =>
      show ? '0px 4px 4px 5px rgba(0, 0, 0, 0.05);' : 'none'};
    padding-top: 2.2rem;
    text-align: left;
    font-size: 1.05rem;
    padding-left: 0.8rem;
    letter-spacing: 0.8px;
    border-radius: 8px;
    z-index: 1;
    border-color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#38B6FF')};
    color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#050a24')};
  }
`;

export const Toggle = styled.svg<SelectProps>`
  height: 20px;
  width: 26px;
  fill: #6fcaff;
  margin-right: 1rem;
  transform: ${({ show }) =>
    show
      ? 'translateY(-9px) scale(1.2) rotateX(180deg)'
      : 'translateY(-12px) scale(1.2)'};
`;

export const Options = styled.div<OptionsProps>`
  button {
    border-radius: 5px;
    padding: 0px 1rem;
    height: 40px;
    text-align: left;
    text-transform: capitalize;
    font-size: 1rem;
    border: none;
    background-color: white;
  }
  button:hover {
    background-color: #e2f4ff;
  }
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  position: absolute;
  widows: 100px;
  background: white;
  max-height: ${({ isShow }) => (isShow ? '250px' : 0)};
  transition: all 0.3s;
  &,
  & * {
    visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  }
  z-index: 8;
  box-shadow: 0px 10px 4px 5px rgba(0, 0, 0, 0.05);
  border: 0.5px solid #9aa0ba;
  border-top: 0px;
  box-sizing: border-box;
  padding: 0px;
  padding-top: 1rem;
  width: 100%;
  margin: 0px;
  transform: translateY(-5px);
  border-radius: 0px 0px 5px 5px;
`;
