import React from 'react';
import { Input } from './../../components/Input/index';
import Button from './../../components/ButtonComponent';
import Back from '../../components/Back';
import { Container } from './styles';
import { URL_PORTAL } from './../../config/urls';
import { authenticate } from '../../services/auth';
import { useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useForm } from 'react-hook-form';

type FormValues = {
  email: string;
  code: string;
};

function FirstAccess() {
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { email: '', code: '' },
  });

  const submit = (data: FormValues) => {
    setIsLoading(true);
    authenticate({
      identifier: data.email,
      password: data.code,
    }).then(() => {
      history.push('/redefine-password');
    });
    setIsLoading(false);
  };

  return (
    <Container>
      <Back text="Voltar" url={URL_PORTAL}></Back>
      <h2>Primeiro acesso</h2>
      <span>Informa seu email e código de acesso para proceguir</span>

      <form onSubmit={handleSubmit(submit)}>
        <Input
          placeholder="Digite aqui seu email"
          label="Email"
          type="email"
          {...register('email', {
            required: 'Email é um campo obrigatório',
          })}
          error={errors.email?.message}
        />
        <Input
          placeholder="Digite aqui seu código"
          label="Código de acesso"
          type="text"
          {...register('code', {
            required: 'Código é um campo obrigatório',
          })}
          error={errors.code?.message}
        />

        <Button type="submit" content={isLoading ? <Loading /> : 'Continuar'} />
      </form>
    </Container>
  );
}

export default FirstAccess;
