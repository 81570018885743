import React from 'react';
import { resetPassword } from '../../hooks/user';
import Button from '../ButtonComponent';
import Loading from '../../components/Loading';
import { InputPassord } from '../Input';
import { useForm } from 'react-hook-form';
import { Container } from './styles';

type FormValues = {
  password: string;
  newPassword: string;
  confNewPassword: string;
};

const ProfilePassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      password: '',
      newPassword: '',
      confNewPassword: '',
    },
  });

  const submit = (data: FormValues) => {
    setIsLoading(true);
    resetPassword(data.newPassword);
    setIsLoading(false);
  };
  return (
    <Container>
      <h2>Redefinir Senha</h2>
      <h5>Informe sua nova senha aqui.</h5>
      <form onSubmit={handleSubmit(submit)}>
        <InputPassord
          placeholder="Digite sua senha atual"
          label="Senha Atual"
          {...register('password', {
            required: 'Senha atual é um campo obrigatório',
          })}
          error={errors.password?.message}
        />
        <InputPassord
          placeholder="Digite sua nova senha"
          label="Nova senha"
          {...register('newPassword', {
            required: 'Nova senha é um campo obrigatório',
          })}
          error={errors.newPassword?.message}
        />
        <InputPassord
          placeholder="Confirme sua nova senha"
          label="Confirmar nova senha"
          {...register('confNewPassword', {
            required: 'Senha é um campo obrigatório',
            validate: {
              passwordIsEqual: (value) => {
                const { newPassword } = getValues();
                return newPassword === value || 'As senhas não são iguais';
              },
            },
          })}
          error={errors.confNewPassword?.message}
        />
        <Button type="submit" content={isLoading ? <Loading /> : 'Continuar'} />
      </form>
    </Container>
  );
};

export default ProfilePassword;
