import styled from '@emotion/styled';

interface TextAreaProps {
  isGreyScale: boolean;
  onChange: any;
  placeholder: string;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  margin-left: 0px;
  position: absolute;
  margin-top: 0.5rem;
  padding-left: 0.8rem;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  opacity: 1;
  z-index: 2;
  color: #38b6ff;
`;

export const TextAreaComponent = styled.textarea<TextAreaProps>`
  background-color: transparent;
  resize: none;
  padding-top: 2.2rem;
  text-align: bottom;
  outline: none;
  font-size: 1.2rem;
  padding-left: 0.8rem;
  width: calc(100% - 0.8rem);
  height: 150px;
  border: 0.3px solid;
  border-color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#38B6FF')};
  letter-spacing: 0.8px;
  border-radius: 8px;
  color: #050a24;
  z-index: 1;
  &:focus-visible {
    border-color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#38B6FF')};
  }
`;
