import React, { ChangeEvent } from 'react';
import { Container, TextAreaComponent, Label } from './styles';

interface Props {
  isGreyScale: boolean;
  placeholder: string;
  labelText: string;
  change: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  value?: string;
}

const TextArea = ({
  isGreyScale,
  placeholder,
  labelText,
  change,
  value,
  disabled,
}: Props) => {
  return (
    <Container>
      <Label>{labelText}</Label>
      <TextAreaComponent
        isGreyScale={isGreyScale}
        placeholder={placeholder}
        onChange={change}
        value={value || ''}
        disabled={disabled}
      />
    </Container>
  );
};

export default TextArea;
