import styled from '@emotion/styled';
interface PageProps {
  isSelect?: boolean;
}

export const Content = styled.div`
  margin: 3% auto;
  width: 100%;
  max-width: 1200px;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  justify-items: center;
`;

export const Option = styled.div`
  width: 10%;
  @media (max-width: 500px) {
    width: 100px;
  }
`;

export const NonIdealState = styled.div`
  font-size: 1rem;
`;

export const Main = styled.div`
  width: 90%;
  padding-left: 6%;
  h2 {
    margin: 0;
    margin-bottom: 2%;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: #050a24;
  }
  h5 {
    margin-bottom: 6%;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #050a24;
  }
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 20px;
  color: #9aa0ba;
  margin-bottom: 1%;
  div {
    display: flex;
    width: 40%;
    justify-content: flex-end;
    div {
      align-items: center;
      justify-content: center;
      width: 15px;
      margin: 0 5px;
    }
  }
`;
export const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const PaginationItem = styled.div<PageProps>`
  margin: 0;
  margin-right: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.isSelect && {
      color: 'white',
      background: '#6FCAFF',
      padding: '0 10px',
    }}
`;
