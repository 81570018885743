import { PORTAL_STUDENT } from '../config/urls';
import { renderError } from './error';
import api from './../services/api/index';
import { PORTAL_ADMIN } from './../config/urls';
import { USER_EMAIL_ADMIN } from './../config/user';
import { showMessage } from './messages';

type ReceivedEssayProps = {
  name: string;
  theme: string;
  email: string;
  id: number;
};

type ReceivedEssayAdminProps = {
  teacherName: string;
  theme: string;
  email?: string;
  name: string;
  id: number;
};

export const essayReceivedStudent = async ({
  name,
  theme,
  email,
  id,
}: ReceivedEssayProps) => {
  try {
    await api.post('/essaySendedStudent', {
      name,
      theme,
      email,
      link: `${PORTAL_STUDENT}/correction/${id}`,
    });
  } catch (err) {
    renderError(err);
  }
};

export const essayCorrectedAdmin = async ({
  teacherName,
  theme,
  name,
  id,
}: ReceivedEssayAdminProps) => {
  try {
    await api.post('/essayCorrectedAdmin', {
      teacherName,
      theme,
      email: USER_EMAIL_ADMIN,
      name,
      link: `${PORTAL_ADMIN}/corrected/${id}`,
    });
  } catch (err) {
    renderError(err);
    showMessage({
      intent: 'error',
      message: 'Erro ao buscar redações corrigidas',
    });
  }
};
