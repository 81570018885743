import api from '../services/api';
import { getUser, saveUser, getUserId } from '../services/auth';
import { renderError } from './error';
import { showMessage } from './messages';

interface UserEditSimpleProps {
  id: string;
  name: string;
  email: string;
  phone: string;
}

export const changeCustomFields = async ({
  id,
  name,
  email,
  phone,
}: UserEditSimpleProps) => {
  try {
    const updatedUser = await api.put(`users/${id}`, {
      name,
      email,
      phone,
    });
    if (updatedUser.data) {
      let updateUser = getUser();
      updateUser.name = name;
      updateUser.email = email;
      updateUser.phone = phone;
      saveUser(updateUser);
      showMessage({
        intent: 'success',
        message: 'Usuário alterado com sucesso!',
      });
    }
  } catch (err) {
    showMessage({ intent: 'error', message: 'Erro ao tentar alterar usuário' });
  }
};

export const resetPassword = async (password: string) => {
  const id = getUser().id;
  if (id) {
    try {
      await api.put(`users/${id}`, {
        password,
      });
      showMessage({
        intent: 'success',
        message: 'Senha atualizada com sucesso',
      });
    } catch (err) {
      showMessage({ intent: 'error', message: 'Erro ao atualizar a senha' });
    }
  }
};

export const newUserPassword = async (password: string) => {
  try {
    return await api.put('/auth/new_password', {
      password,
    });
  } catch (err) {
    showMessage({
      intent: 'error',
      message: 'Erro ao cadastrar uma nova senha',
    });
  }
};

type ResetPassword = {
  code: string;
  password: string;
  passwordConfirmation: string;
};

export const resetUserPassword = async ({
  code,
  password,
  passwordConfirmation,
}: ResetPassword): Promise<MessageProps | undefined> => {
  if (passwordConfirmation !== password) {
    return { status: 'error', message: 'As senhas não são iguais!' };
  }
  try {
    const response = await api.post('/auth/reset-password', {
      code,
      password,
      passwordConfirmation,
    });
    if (response.status === 200) {
      return { status: 'success', message: 'Usuário alterado com sucesso!' };
    }
  } catch (err) {
    renderError(err);
    return { status: 'error', message: 'Erro ao tentar alterar usuário.' };
  }
};

type FormValues = {
  password: string;
  confirmPassword: string;
};

interface MessageProps {
  status: 'success' | 'error' | 'warning';
  message: string;
}

export const redefinePassword = async ({
  password,
  confirmPassword,
}: FormValues): Promise<MessageProps | undefined> => {
  try {
    if (confirmPassword !== password) {
      return { status: 'error', message: 'As senhas não são iguais!' };
    }
    const user = await api.put(`/users/${getUserId()}`, {
      password,
      redefinedPassword: true,
    });
    if (user.status === 200) {
      return { status: 'success', message: 'Usuário atualizado com sucesso!' };
    }
  } catch (err) {
    renderError(err);
    return { status: 'error', message: 'Não foi possivel atualizar a senha.' };
  }
};
