import { ComponentType } from 'react';
import { RouteProps as ReactRouteProps } from 'react-router-dom';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import Corrected from '../pages/Corrected';
import Profile from '../pages/Profile';
import Correction from '../pages/Correction';
import Home from '../pages/Home';
import FirstAccess from '../pages/FirstAccess';
import RedefinePassword from '../pages/RedefinePassword';
import RecoverPassword from '../pages/RecoverPassword';

export interface RouteProps extends ReactRouteProps {
  component: ComponentType;
  private?: boolean;
  routes?: RouteProps[];
}

const options: RouteProps[] = [
  {
    path: '/',
    exact: true,
    component: Login,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/home',
    exact: true,
    private: true,
    component: Home,
  },
  {
    path: '/first-access',
    exact: true,
    component: FirstAccess,
  },
  {
    path: '/redefine-password',
    exact: true,
    component: RedefinePassword,
  },
  {
    path: '/profile',
    exact: true,
    private: true,
    component: Profile,
  },
  {
    path: '/correction/:id',
    exact: true,
    private: true,
    component: Correction,
  },
  {
    path: '/corrected/:id',
    exact: true,
    private: true,
    component: Corrected,
  },
  {
    path: '/reset-password/:code',
    exact: true,
    component: RecoverPassword,
  },
  {
    path: '/recover-password/:code',
    exact: true,
    component: RecoverPassword,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
  },
];

export default options;
