import React from 'react';
import { InputPassord } from '../../components/Input';
import Button from '../../components/ButtonComponent';
import { Container, Content, Back } from './styles';
import { showMessage } from '../../hooks/messages';
import { resetUserPassword } from '../../hooks/user';
import { URL_PORTAL } from './../../config/urls';
import { BackCustomized } from '../../components/BackComponent';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

interface ResetProps {
  code: string;
}

const RecoverPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { password: '', passwordConfirmation: '' },
  });
  const history = useHistory();

  const { code } = useParams<ResetProps>();

  const submit = async (data: FormValues) => {
    setIsLoading(true);
    const response = await resetUserPassword({ ...data, code });
    response &&
      showMessage({ intent: response.status, message: response.message });
    setIsLoading(false);
    if (response?.status === 'success') {
      setTimeout(() => history.push('/home'), 2000);
    }
  };

  return (
    <Container>
      <Back>
        <BackCustomized text="voltar" url={URL_PORTAL} />
      </Back>
      <Content>
        <h2>Defina uma nova senha de acesso</h2>
        <p>Ao definir uma nova senha você precisará fazer login novamente.</p>
        <form onSubmit={handleSubmit(submit)}>
          <InputPassord
            placeholder="Defina sua nova senha"
            label="Nova senha"
            {...register('password', {
              required: 'Senha é um campo obrigatório.',
            })}
            error={errors.password?.message}
          />
          <InputPassord
            placeholder="Confirme sua nova senha"
            label="Confirmar nova senha"
            {...register('passwordConfirmation', {
              required: 'Confirmar senha é um campo obrigatório.',
              validate: {
                passwordIsEqual: (value) => {
                  const { password } = getValues();
                  return password === value || 'As senhas não são iguais';
                },
              },
            })}
            error={errors.passwordConfirmation?.message}
          />
          <Button
            type="submit"
            content={isLoading ? <Loading /> : 'definir nova senha'}
          />
        </form>
      </Content>
    </Container>
  );
};
export default RecoverPassword;
