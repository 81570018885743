import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Background,
  From,
  Title,
  Content,
  Info,
  InfoComponent,
  Division,
  Grid,
  Competences,
  CompetencesGrid,
  Score,
  CompetenceItem,
  Total,
  Essay,
  EssayParagraph,
  Comments,
  Tag,
  AddComments,
  ShowComments,
  CommentCard,
  DeleteComment,
  Final,
} from './styles';
import Layout from '../../components/Layout';
import Back from '../../components/Back';
import BreadCrumbs from '../../components/BreadCrumbs';
import Checkbox from '../../components/Check';
import api from '../../services/api';
import { renderError } from './../../hooks/error';
import Conversation from '../../components/Conversation';
import { showMessage } from '../../hooks/messages';
import TextArea from './../../components/TextArea/index';
import ToolTip from '../../components/ToolTip';
import { CompetenciesProps } from './../../domain/Competencies/Competencies';
import { useHistory } from 'react-router-dom';

interface UserProps {
  id: number;
  firstname?: string;
  lastname?: string;
  name: string;
  email: string;
}

interface ThemeProps {
  id: number;
  name: string;
}

interface EssayGradesProps {
  id: number;
  id_essay: EssayProps;
  id_competency: CompetencyObjectProps;
  score: number;
  created_at: string;
  updated_at: string;
}

interface ObjectProps {
  id: number;
  name: string;
}

interface ParamTypes {
  id: string;
}

interface EssayProps {
  id: number;
  title: string;
  score: string;
  idUser: UserProps;
  theme: ThemeProps;
  final_comment: string;
  essay_status: number;
  created_at: string;
}

interface ParagraphSimpleProps {
  id: string;
  id_essay: number;
  text: string;
}

interface ParagraphProps {
  id: string;
  id_essay: EssayProps;
  text: string;
}

interface CompetencyObjectProps {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  created_by: number;
  updated_by: number;
}

interface CompetencyProps extends CompetencyObjectProps {
  initial?: boolean;
  color: string;
  score: number;
  obj: ObjectProps;
  competency: CompetenciesProps;
}

interface CommentProps {
  id: number;
  created_at: string;
  id_paragraph: null | ParagraphSimpleProps;
  id_competency: null | CompetencyProps;
  comment: string;
}

const Correction = () => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const score = [0, 40, 60, 80, 120, 160, 180, 200];
  const [competences, setCompetences] = useState<Array<CompetencyProps>>([]);
  const [grades, setGrades] = useState<Array<EssayGradesProps>>([]);
  const [essay, setEssay] = useState<EssayProps>({
    id: 0,
    title: '',
    score: '',
    final_comment: '',
    essay_status: 2,
    theme: {
      id: 0,
      name: 'Geral',
    },
    idUser: {
      id: 0,
      email: '',
      name: '',
    },
    created_at: '',
  });

  const [allComments, setAllComments] = useState<Array<CommentProps>>([]);
  const [paragraph, setParagraph] = useState<Array<ParagraphProps>>([]);
  const [finalComment, setFinalComment] = useState('');

  useEffect(() => {
    try {
      Promise.all([
        api
          .get(`/essays/${id}`)
          .then(({ data }) => {
            if (data.essay_status.status === 'A Corrigir') {
              history.push(`/correction/${data.id}`);
            }
            setEssay(data);
            setFinalComment(data.final_comment);
          })
          .catch((err) => history.push('/home')),

        api.get(`/essay-paragraphs?id_essay=${id}`).then(({ data }) => {
          setParagraph(data);
        }),

        api.get(`/essay-grades?id_essay=${id}`).then(({ data }) => {
          setGrades(data);
        }),

        api.get('/essay-comments').then(({ data }) => {
          setAllComments(
            data.filter(
              (elem: CommentProps) =>
                elem.id_paragraph?.id_essay === parseInt(id)
            )
          );
        }),
      ]);
    } catch (err) {
      renderError(err);
    }
  }, [id, history]);

  useEffect(() => {
    const color = ['#5375CD', '#4AC776', '#FF6F69', '#9E74D4', '#FFAD33'];
    try {
      api.get('/competencies').then((response) => {
        const competenceArray = response.data.map(
          (elem: CompetencyObjectProps, index: number) => {
            let oldGrades = grades.filter(
              (grade) => grade.id_competency.id === elem.id
            );
            if (oldGrades.length) {
              const item = {
                initial: false,
                name: elem.name,
                obj: oldGrades[0].id_competency,
                color: color[index],
                score: oldGrades[0].score,
                competency: elem,
              };
              return item;
            } else {
              const item = {
                initial: true,
                name: elem.name,
                obj: elem,
                color: color[index],
                score: 0,
                competency: elem,
              };
              return item;
            }
          }
        );
        setCompetences(competenceArray);
      });
    } catch (err) {
      renderError(err);
    }
  }, [grades]);

  const getFinalScore = () => {
    let score = 0;
    competences.map((elem: CompetencyProps) => {
      score = score + elem.score;
      return elem;
    });
    return score;
  };

  const getWords = (text: string) => {
    let words = text.replace(/(^\s*)|(\s*$)/gi, '');
    words = words.replace(/[ ]{2,}/gi, ' ');
    words = words.replace(/\n /, '\n');
    return words.split(' ').length;
  };

  const getAllWords = () => {
    const words = paragraph.map((elem: ParagraphProps) => elem.text).join(' ');
    words.replace(/(^\s*)|(\s*$)/gi, '');
    words.replace(/[ ]{2,}/gi, ' ');
    words.replace(/\n /, '\n');
    return words.split(' ').length;
  };

  const getLines = () => {
    const words = getAllWords() / 15;
    return Math.round(words);
  };

  const getDate = () => {
    const date = new Date(essay.created_at);
    return date.toLocaleDateString();
  };

  const renderInfo = (title: string, content: string) => {
    return (
      <InfoComponent>
        <label>{title}</label>
        <h3>{content}</h3>
      </InfoComponent>
    );
  };

  const renderParagraph = (number: string, content: string) => {
    return (
      <EssayParagraph key={`${'paragraph-essay ' + number}`}>
        <div>
          <label>{number + 'º Parágrafo'}</label>
          <h2>{`${getWords(content)} Palavras escritas`}</h2>
        </div>
        <h3>{`${'\xa0\xa0\xa0\xa0\xa0' + content}`}</h3>
      </EssayParagraph>
    );
  };

  const renderComment = (elem: CommentProps) => {
    const commentParagraph = paragraph
      .map((paragraph: ParagraphProps) => paragraph.id)
      .sort()
      .indexOf(elem.id_paragraph ? elem.id_paragraph.id : '0');

    return (
      <CommentCard key={`${elem.created_at}`}>
        <div>
          <Tag
            paragraph={{
              name: `Parágrafo ${commentParagraph + 1}`,
              color: '#6FCAFF',
            }}
            competency={{
              name: elem.id_competency?.name || '',
              color: competences.filter(
                (comp: CompetencyProps) =>
                  comp.name === elem.id_competency?.name
              )[0]?.color,
            }}
          />
          <DeleteComment />
        </div>
        <p>{elem.comment}</p>
      </CommentCard>
    );
  };

  return (
    <Layout>
      {essay && (
        <Background>
          <From>
            <Title>
              <Back />
              <div>
                <div>
                  <BreadCrumbs
                    crumbs={[
                      { text: 'Redações', url: '/home' },
                      { text: 'Corrigidas', url: '/home', params: { type: 'Concluídas' } },
                      { text: 'Redação corrigida', url: '' },
                    ]}
                  ></BreadCrumbs>
                </div>
                <div />
              </div>

              <h1>Correção</h1>
            </Title>
            <Content>
              <Info>
                {renderInfo('Enviada em', getDate())}
                {renderInfo('Tema', essay.theme.name)}
                {renderInfo('Título', essay.title)}
                {renderInfo('Enviada por', essay.idUser.name)}
                <Division />
                {renderInfo('Linhas Aproximadas', getLines().toString())}
                {renderInfo('Palavras Escritas', getAllWords().toString())}
                {renderInfo('parágrafos', paragraph.length.toString())}
              </Info>
              <Grid>
                <div>
                  <Essay>
                    {paragraph.map((elem: ParagraphProps, index: number) =>
                      renderParagraph(`${index + 1}`, elem.text)
                    )}
                  </Essay>
                  <Competences>
                    <h3>Pontuação por competência</h3>
                    <CompetencesGrid>
                      <Score>
                        {score.map((elem: number, index: number) => (
                          <div key={`${elem + index} `}>{elem}</div>
                        ))}
                      </Score>
                      <div>
                        {competences.map((competence: CompetencyProps) => (
                          <div key={competence.name}>
                            <CompetenceItem color={competence.color}>
                              <ToolTip
                                name={competence.name}
                                background={competence.color}
                                competency={competence.competency}
                              />
                              <div>
                                {score.map((value: number, index: number) => {
                                  return (
                                    <Checkbox
                                      key={`checkbox${value}`}
                                      isChecked={
                                        competence.score === score[index]
                                      }
                                      name={competence.score.toString()}
                                      text=""
                                      change={() => {
                                        showMessage({
                                          intent: 'error',
                                          message:
                                            'Não é possível alterar a nota',
                                        });
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </CompetenceItem>
                          </div>
                        ))}
                        <Total>
                          <h3>Nota Total</h3>
                          <div>{getFinalScore()}</div>
                        </Total>
                      </div>
                    </CompetencesGrid>
                  </Competences>
                </div>
                <Comments>
                  <AddComments>
                    <h3>Comentários</h3>
                    <p>Aqui estão as observações feitas sobre esta redação.</p>
                  </AddComments>
                  <ShowComments>
                    {allComments.length
                      ? allComments.map((elem: CommentProps) =>
                          renderComment(elem)
                        )
                      : 'Você ainda não inseriu nenhum comentário.'}
                  </ShowComments>
                </Comments>
              </Grid>
            </Content>
            <Final>
              <TextArea
                isGreyScale={finalComment?.length ? false : true}
                placeholder="Escreva aqui suas observações finais sobre esta redação;"
                labelText="Comentário final"
                change={() => {}}
                value={essay.final_comment ? essay.final_comment : ''}
                disabled
              />
            </Final>
            {id && <Conversation essay={parseInt(id)} />}
          </From>
        </Background>
      )}
    </Layout>
  );
};

export default Correction;
